import styled from "styled-components";
import {
  MaterialCubicBezier,
  MobileWidth,
  PrimaryColor,
  SectionPadding,
  SectionPaddingMobile,
} from "../../GlobalStyle";

export const PromoWrapper = styled.div`
  color: #fff;
  padding: ${SectionPadding};
  @media screen and (max-width: ${MobileWidth}) {
    padding: ${SectionPaddingMobile};
  }

  .promo-flex {
    display: flex;
    @media screen and (max-width: ${MobileWidth}) {
      flex-direction: column;
    }

    .col {
      flex-basis: 50%;
      padding: 0 60px;
      position: relative;
      @media screen and (max-width: ${MobileWidth}) {
        padding: 0 10px 0 20px;
      }

      p {
        position: relative;
        &:before {
          border: 2px solid ${PrimaryColor};
          border-radius: 50%;
          content: "";
          height: 4px;
          width: 4px;
          position: absolute;
          left: -15px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      ul {
        font-size: 14px;
        line-height: 18px;
        margin-top: -10px;
        padding-left: 18px;
      }

      img {
        width: 100%;
      }

      video {
        border: 1px solid rgba(255, 255, 255, 0.1);
        width: 100%;
      }

      .side-title {
        color: rgba(255, 255, 255, 0.1);
        font-size: 70px;
        font-weight: 700;
        position: absolute;
        top: 0;
        text-transform: uppercase;
        transform-origin: top right;
        transform: rotate(-90deg);
        z-index: -1;
        @media screen and (max-width: ${MobileWidth}) {
          font-size: 40px;
        }
      }

      .promo-item {
        cursor: pointer;
        height: 380px;
        background-position: top;
        background-size: cover;
        margin-bottom: 20px;
      }

      &.event {
        @media screen and (max-width: ${MobileWidth}) {
          margin-bottom: 30px;
        }
        .side-title {
          left: -280px;
          @media screen and (max-width: ${MobileWidth}) {
            left: -168px;
          }
        }
      }

      &.promo {
        .side-title {
          left: -280px;
          @media screen and (max-width: ${MobileWidth}) {
            left: -168px;
          }
        }
      }
    }
  }
`;
