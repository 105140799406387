import React, { createRef } from "react";
import { SectionTitle, Wrapper } from "../../GlobalStyle";
import useOnScreen from "../Util/UseOnScreen";
import ClubItem from "./ClubItem";
import { ClubWrapper } from "./Styled";

const ClubLogos = [
  {
    src: require("../../images/club/club1.webp").default,
    alt: "club1",
  },
  {
    src: require("../../images/club/club2.webp").default,
    alt: "club2",
    size: 200,
  },
  {
    src: require("../../images/club/club3.webp").default,
    alt: "club3",
  },
  {
    src: require("../../images/club/club4.webp").default,
    alt: "club4",
  },
  {
    src: require("../../images/club/club5.webp").default,
    alt: "club5",
  },
];

const PartnerLogos = [
  {
    src: require("../../images/club/partner1.webp").default,
    alt: "partner1",
  },
  {
    src: require("../../images/club/partner2.webp").default,
    alt: "partner2",
  },
  {
    src: require("../../images/club/partner3.webp").default,
    alt: "partner3",
  },
  {
    src: require("../../images/club/partner4.webp").default,
    alt: "partner4",
  },
  {
    src: require("../../images/club/partner5.webp").default,
    alt: "partner5",
  },
  {
    src: require("../../images/club/partner6.webp").default,
    alt: "partner6",
  },
  {
    src: require("../../images/club/partner8.webp").default,
    alt: "partner8",
    size: 250,
  },
];

function Club() {
  const clubRef = createRef();
  const isClubVisible = useOnScreen(clubRef);
  const partnerRef = createRef();
  const isPartnerVisible = useOnScreen(partnerRef);

  return (
    <ClubWrapper id="associates">
      <SectionTitle className="white-line">
        <span>Associates</span>
      </SectionTitle>

      <div className="images-row partner" ref={partnerRef}>
        <Wrapper>
          <div className="row-title">Partners</div>
          <div className="logo-wrapper">
            {PartnerLogos.map(({ src, alt, size }, index) => (
              <ClubItem
                picture={src}
                isVisible={isPartnerVisible}
                delay={index * 300}
                key={alt}
                size={size}
              />
            ))}
          </div>
        </Wrapper>
      </div>

      <div className="images-row club" ref={clubRef}>
        <Wrapper>
          <div className="row-title">Clubs</div>
          <div className="logo-wrapper">
            {ClubLogos.map(({ src, alt, size }, index) => (
              <ClubItem
                picture={src}
                isVisible={isClubVisible}
                delay={index * 300}
                key={alt}
                size={size}
              />
            ))}
          </div>
        </Wrapper>
      </div>

      {/*
      <div className="marquee">
        <div className="marquee-content">
          {PartnerLogos.map((item, index) => (
            <img src={item} key={index} alt="Partner" />
          ))}
          {PartnerLogos.map((item, index) => (
            <img src={item} key={index} alt="Partner" />
          ))}
        </div>
      </div>
      */}
    </ClubWrapper>
  );
}

export default Club;
