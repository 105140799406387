import React from "react";
import { PricingWrapper } from "./Styled";
import { SectionTitle, Wrapper } from "../../GlobalStyle";

const PRICING = {
  "Sunoco Engine Oil": {
    items: [
      {
        name: "Qualia 4L",
        price: "$120 with oil filter (Top Up at $15 / Litre)",
      },
      {
        name: "Svelt 4L",
        price: "$150 with oil filter (Top Up $25 / Litre)",
      },
      {
        name: "Svelt Euro/ C3 4L",
        price: "$160 with oil filter (Top Up $25 / Litre)",
      },
      {
        name: "Brill",
        price: "$40 / Litre (Excludes oil filter & labor)",
      },
    ],
  },
  "LiquiMoly  Engine  Oil": {
    items: [
      {
        name: "Top Tec 5W-40 4L",
        price: "$128",
      },
      {
        name: "Molygen 5W-30 4L",
        price: "$128",
      },
    ],
  },
  "Servicing Package": {
    items: [
      {
        name: "Sunoco Qualia (3 + 1 Package)",
        price: "$388 (Top Up at $15 / Litre)",
      },
      {
        name: "Sunoco Svelt (3 + 1 Package)",
        price: "$598 (Top Up at $25 / Litre)",
      },
      {
        name: "LiquiMoly 5W-40 OR 5W-30 (3 + 1 Package)",
        price: "$418 (Top up at $20 / Litre)",
      },
      {
        name: "Perfecto Flush",
        price: "$198",
      },
      {
        name: "Brake Fluid Flush",
        price: "$140 (Brake Fluid $40/Litre + Flush Machine $100)",
      },
      {
        name: "Brake Fluid",
        price: "$40/Litre",
      },
    ],
    descriptionIndex: 2,
    description: () => (
      <div className="price-description">
        Packages includes:
        <ul>
          <li>Engine Oil 4 litres</li>
          <li>Oil Filter</li>
          <li>30 Points Check</li>
          <li>Car Wash/ Vacuum</li>
        </ul>
      </div>
    ),
  },
};

function Pricing() {
  return (
    <PricingWrapper id="pricing">
      <Wrapper>
        <SectionTitle className="white-font">
          <span>Pricing</span>
        </SectionTitle>
        <div className="price-table">
          {Object.entries(PRICING).map(([name, { items, description, descriptionIndex }]) => (
            <div className="price-group" key={name}>
              <h3>{name}</h3>
              <div className="price-section">
                {items.map(({ name, price }, index) => (
                  <>
                    <div className="price-item" key={name}>
                      <div className="price-label">{name}</div>
                      <div className="price-separator" />
                      <div className="price-pricing">{price}</div>
                    </div>
                    {description && index === descriptionIndex && description()}
                  </>
                ))}
              </div>
            </div>
          ))}
        </div>
      </Wrapper>
    </PricingWrapper>
  );
}

export default Pricing;
