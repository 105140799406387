import React from "react";
import Flickity from "react-flickity-component";
import { images } from "./index";

const flickityOptions = {
  initialIndex: 0,
  wrapAround: true,
  autoPlay: true,
  groupCells: true,
};

function Carousel({ setIsOpen, setPhotoIndex }) {
  return (
    <Flickity
      className={"carousel"} // default ''
      elementType={"div"} // default 'div'
      options={flickityOptions} // takes flickity options {}
      disableImagesLoaded={false} // default false
      reloadOnUpdate // default false
      static // default false
    >
      {images.map((src, index) => {
        return (
          <div
            onClick={() => {
              setIsOpen(true);
              setPhotoIndex(index);
            }}
            className="carousel-item"
            style={{
              backgroundImage: `url(${src})`,
            }}
            key={src}
          />
        );
      })}
    </Flickity>
  );
}

const MemoizedCarousel = React.memo(Carousel);

export default MemoizedCarousel;
