import styled from "styled-components";
import {
  SectionPadding,
  SectionPaddingMobile,
  MobileWidth,
  PrimaryColor,
} from "../../GlobalStyle";
import PriceBg from "../../images/price/price-bg.png";

export const PricingWrapper = styled.div`
  color: #fff;
  padding: ${SectionPadding};
  background: url(${PriceBg}) center bottom / cover no-repeat;
  @media screen and (max-width: ${MobileWidth}) {
    padding: ${SectionPaddingMobile};
  }
  .price-table {
    .price-group {
      &:not(:last-child) {
        margin-bottom: 60px;
      }
      h3 {
        color: rgba(255, 255, 255, 0.4);
      }
    }
    .price-section {
      .price-item {
        display: flex;
        align-items: center;
        font-size: 1.25rem;
        @media screen and (max-width: ${MobileWidth}) {
          font-size: 1rem;
          flex-flow: row wrap;
        }
        &:not(:last-child) {
          margin-bottom: 20px;
        }
        .price-label {
          margin-right: 20px;
          @media screen and (max-width: ${MobileWidth}) {
            width: 100%;
          }
        }
        .price-separator {
          margin: 0 auto;
          height: 2px;
          width: 50px;
          flex-grow: 1;
          background-color: rgba(255, 255, 255, 0.2);
          @media screen and (max-width: ${MobileWidth}) {
            display: none;
          }
        }
        .price-pricing {
          margin-left: 20px;
          color: ${PrimaryColor};
          @media screen and (max-width: ${MobileWidth}) {
            margin-left: 0;
          }
        }
      }

      .price-description {
        background-color: rgba(33, 33, 33, 0.5);
        border: 0.1rem solid ${PrimaryColor};
        padding: 20px;
        margin: 10px 0 40px;
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
        @media screen and (max-width: ${MobileWidth}) {
          flex-flow: row wrap;
        }
        ul {
          list-style: none;
          display: flex;
          align-items: center;
          margin: 0 0 0 20px;
          padding: 0;
          @media screen and (max-width: ${MobileWidth}) {
            flex-flow: row wrap;
            margin: 0;
          }
          li {
            margin: 0 0 0 10px;
            @media screen and (max-width: ${MobileWidth}) {
              width: 100%;
              margin: 0;
            }
            &:not(:last-child) {
              &:after {
                content: '+';
                color: ${PrimaryColor};
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }
`;
