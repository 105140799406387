import styled, { keyframes } from "styled-components";
import {
  MaterialCubicBezier,
  MobileWidth,
  PrimaryColor,
} from "../../GlobalStyle";

const TitleAnimation = keyframes`
  0% {
    transform: scale(0) rotate(10deg);
  }
  50% {
    transform: scale(1.1) rotate(-5deg);
  }
  100% {
    transform: scale(1) rotate(0);
  }
`;

export const BannerWrapper = styled.div`
  > div {
    position: relative;
  }
  height: 100vh;
  min-height: 400px;
  position: relative;
  .carousel {
    height: 100%;
    background-color: #000;
    .carousel-item {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
    }
    .flickity-button {
      display: none;
    }
    .flickity-page-dots {
      bottom: 50px;
      .dot {
        height: 6px;
        width: 30px;
        border-radius: 10px;
        opacity: 1;
        background-color: rgba(255, 255, 255, 0.5);
        transition: all 200ms ${MaterialCubicBezier};
        &.is-selected {
          width: 50px;
          background-color: ${PrimaryColor};
        }
      }
    }
  }
`;

export const TextWrapper = styled.div`
  position: absolute;
  top: 50vh;
  left: 0;
  z-index: 10;
  transform: translateY(-50%);
  @media screen and (max-width: ${MobileWidth}) {
    padding-left: 20px;
    top: 250px;
  }
  .sub-title {
    font-size: 40px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: -10px;
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1));
    @media screen and (max-width: ${MobileWidth}) {
      font-size: 20px;
      margin-bottom: 0;
    }
  }
  .title {
    margin: 0;
    font-size: 50px;
    color: ${PrimaryColor};
    text-transform: uppercase;
    filter: drop-shadow(0 0 12px rgba(0, 0, 0, 0.4));
    animation: ${TitleAnimation} 500ms ${MaterialCubicBezier};
    @media screen and (max-width: ${MobileWidth}) {
      font-size: 30px;
    }
  }
  .cta {
    padding: 0 80px;
    border: 4px solid ${PrimaryColor};
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 16px;
    margin-top: 20px;
    position: relative;
    transition: all 400ms ${MaterialCubicBezier};
    background-color: rgba(0, 0, 0, 0.2);
    @media screen and (max-width: 400px) {
      font-size: 14px;
      padding: 0 40px;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: ${PrimaryColor};
      transform-origin: left;
      transform: scaleX(0);
      transition: all 400ms ${MaterialCubicBezier};
      z-index: -1;
    }
    &:hover {
      color: #000;
      background-color: transparent;
      &:before {
        transform: scaleX(1);
      }
    }
  }
`;
