import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import { SectionTitle, Wrapper } from "../../GlobalStyle";
import { PromoWrapper } from "./Styled";

import Event1 from "../../images/event1.jpg";
import Event2 from "../../images/event2.jpg";
import Promo1 from "../../images/promo1.webp";
import Promo2 from "../../images/promo2.webp";
import Promo3 from "../../images/promo3.webp";
import Promo4 from "../../images/promo4.webp";
import Products from "./Products";
import Pricing from "../Pricing";

const images = [Promo1, Promo4];
const desc = ["LiquiMoly Promotion (Click to zoom image)", "Panel Beating"];

function Promo() {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <PromoWrapper id="products">
      <Wrapper>
        <SectionTitle className="white-font">
          <span>Products</span>
        </SectionTitle>

        <Products />

        <SectionTitle className="white-font">
          <span>Events & Promo</span>
        </SectionTitle>

        <div className="promo-flex">
          <div className="col event">
            <div className="side-title">EVENTS</div>

            <p>CAS Garage Grand Opening</p>
            <video controls autoPlay muted>
              <source
                src="https://video.wixstatic.com/video/3d08fc_a6ef0176ebae43628e742fd5e817ba42/1080p/mp4/file.mp4"
                type="video/mp4"
              />
            </video>

            <p>EACS Genting Trip 2019</p>
            <a
              href="https://www.facebook.com/CASGarageSG/posts/747459262425105"
              target="_blank"
            >
              <img src={Event1} alt="EACS Genting Trip 2019" />
            </a>

            <p>ECCS Gathering 2019</p>
            <a
              href="https://www.facebook.com/CASGarageSG/posts/712781099226255"
              target="_blank"
            >
              <img src={Event2} alt="ECCS Gathering 2019" />
            </a>
          </div>

          <div className="col promo">
            <div className="side-title">PROMO</div>

            <p>Perfecto Flush</p>
            <video controls autoPlay muted>
              <source src="videos/promo_video_1.mp4" type="video/mp4" />
            </video>
            {images.map((image, index) => {
              return (
                <>
                  <p>{desc[index]}</p>
                  <img
                    key={image}
                    className="promo-item"
                    style={{
                      backgroundImage: `url(${image})`,
                    }}
                    onClick={() => {
                      setIsOpen(true);
                      setPhotoIndex(index);
                    }}
                  />
                </>
              );
            })}
          </div>
        </div>
      </Wrapper>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </PromoWrapper>
  );
}

export default Promo;
