import styled from "styled-components";
import {
  MaterialCubicBezier,
  MobileWidth,
  PrimaryColor,
} from "../../GlobalStyle";

export const FooterWrapper = styled.div`
  background-color: ${PrimaryColor};
  font-size: 13px;
  padding: 30px 0;

  .footer-flex {
    display: flex;
    flex-wrap: wrap;

    .col {
      display: flex;
      flex-direction: column;
      flex-basis: calc(100% / 3);
      @media screen and (max-width: ${MobileWidth}) {
        flex-basis: calc(100% / 2);
      }

      &.logo {
        img {
          width: 90px;
        }

        .sns-wrapper {
          margin-top: auto;
          @media screen and (max-width: ${MobileWidth}) {
            margin-top: 10px;
          }

          svg {
            color: #000;
            margin-right: 5px;
            transition: all 200ms ${MaterialCubicBezier};
            &:hover {
              color: #fff;
            }
          }
        }
      }

      &.address {
        .phone-num {
          margin-top: auto;
        }
      }

      &.map {
        @media screen and (max-width: ${MobileWidth}) {
          flex-basis: 100%;
        }
        iframe {
          height: 150px;
          width: 100%;
          border: 4px solid rgba(0, 0, 0, 0.1);
          @media screen and (max-width: ${MobileWidth}) {
            margin-top: 15px;
          }
        }
      }
    }
  }
`;
