import React, { useState } from "react";
import { SectionTitle, Wrapper } from "../../GlobalStyle";
import { AboutWrapper } from "./Styled";

import AboutPic from "../../images/about.jpg";
import MemoizedLightboxComponent from "./Lightbox";
import Carousel from "./Carousel";

export const images = [...new Array(17).keys()].map(
  (item, index) => require(`../../images/about/about${index + 1}.jpeg`).default
);

function About() {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <AboutWrapper id="about">
      <Wrapper>
        <SectionTitle>
          <span>ABOUT US</span>
        </SectionTitle>

        <img src={AboutPic} alt="About Us" />
        <div className="about-us-images">
          <Carousel setPhotoIndex={setPhotoIndex} setIsOpen={setIsOpen} />
        </div>

        <div className="paragraph-wrapper">
          <p>
            Aldrin Automotive went into partnership with Ms Cher Shin Yip and we
            changed the Company’s name to CAS Garage Pte Ltd on 31st December
            2018.
          </p>
          <p>
            We relocate to Kaki Bukit Autobay; taking up 3 Units of workshops
            and expanded our operations and specialized into Panel Beating,
            Servicing and Repair, Accident Claims and Reporting and Spray
            Painting works.
          </p>
          <p>
            Our Panel Beating Department is fully equipped with new equipment
            and a Straightening Frame Machine acquired in September 2019.
          </p>
          <p>
            Our Master Panel Beaters are guided by years of experience and
            skills in restoring damaged vehicle bodies back to their factory
            state and jobs were delivered in satisfactory condition.
          </p>
          <p>
            Our partnered Spray Painting Department is also based in Kaki Bukit
            Autobay; Key achievements include multiple color mixing and respray
            services for high end luxury vehicles and Korean and Japanese
            vehicles.
          </p>
          <p>
            For the past years, we have built very good rapport with many Car
            Clubs; participating and sponsoring in their activities.
          </p>
          {/*<p>*/}
          {/*  CAS Garage has been in the Motor Claims Industry for more than 13*/}
          {/*  years; specialized in Third Party Accident Claims.*/}
          {/*</p>*/}
          {/*<p>*/}
          {/*  Established in 2006 as Aldrin Automotive; Sole Proprietorship based*/}
          {/*  in Eunos Industrial Estate.*/}
          {/*</p>*/}
          {/*<p>*/}
          {/*  Aldrin Automotive went into partnership with Ms Cher Shin Yip and we*/}
          {/*  changed the Company’s name to CAS Garage Pte Ltd on 31st December*/}
          {/*  2018.*/}
          {/*</p>*/}
          {/*<p>*/}
          {/*  We relocate to Kaki Bukit Autobay; taking up 3 Units of workshops*/}
          {/*  and expanded our operations and specialized into Panel Beating and*/}
          {/*  Spray Painting works.*/}
          {/*</p>*/}
          {/*<p>*/}
          {/*  Our Panel Beating Department is fully equipped with new equipment*/}
          {/*  and a Straightening Frame Machine acquired in September 2019.*/}
          {/*</p>*/}
          {/*<p>*/}
          {/*  Our Master Panel Beaters are guided by years of experience and*/}
          {/*  skills in restoring damaged vehicle bodies back to their factory*/}
          {/*  state and jobs were delivered in satisfactory condition.*/}
          {/*</p>*/}
          {/*<p>*/}
          {/*  Our Spray Painting Department is in partnership with Bao He Spray*/}
          {/*  Paint Shop (based in Kaki Bukit Autobay); Key achievements include*/}
          {/*  multiple colour mixing and respray services for high end luxury*/}
          {/*  vehicles and Korean and Japanese vehicles.*/}
          {/*</p>*/}
          {/*<p>*/}
          {/*  For the past years, we have built very good rapport with many Car*/}
          {/*  Clubs; participating and sponsoring in their activities.*/}
          {/*</p>*/}
          {/*<p>*/}
          {/*  Partner Ms Cher Shin Yip has 10 years of experience with OTO*/}
          {/*  Bodycare; an International Marketing and Product Development.*/}
          {/*</p>*/}
          {/*<p>*/}
          {/*  She leads CAS Garage in Marketing and Social Media Strategy, via*/}
          {/*  Facebook, Instagram and Company’s Website:{" "}*/}
          {/*  <a href="https://www.casgaragesg.com/">www.casgaragesg.com</a>*/}
          {/*</p>*/}
        </div>
      </Wrapper>
      {isOpen && (
        <MemoizedLightboxComponent
          setIsOpen={setIsOpen}
          photoIndex={photoIndex}
          setPhotoIndex={setPhotoIndex}
        />
      )}
    </AboutWrapper>
  );
}

export default About;
