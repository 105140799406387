import React from "react";
import { BannerWrapper } from "./Styled";
import Flickity from "react-flickity-component";

import Banner1 from "../../images/banner-01.png";
import Banner2 from "../../images/banner-02.jpeg";
import Banner3 from "../../images/banner-03.jpeg";
import { Wrapper } from "../../GlobalStyle";
import TitleTicker from "./TitleTicker";

const BANNER_LIST = [
  {
    src: Banner1,
  },
  {
    src: Banner2,
  },
  {
    src: Banner3,
  },
];

const flickityOptions = {
  initialIndex: 0,
  wrapAround: true,
  autoPlay: true,
};

function Banner() {
  return (
    <BannerWrapper id="home">
      <Wrapper>
        <TitleTicker />
      </Wrapper>
      <Flickity
        className={"carousel"} // default ''
        elementType={"div"} // default 'div'
        options={flickityOptions} // takes flickity options {}
        disableImagesLoaded={false} // default false
        reloadOnUpdate // default false
        static // default false
      >
        {BANNER_LIST.map((banner, index) => {
          const { src } = banner;
          return (
            <div
              className="carousel-item"
              style={{
                backgroundImage: `url(${src})`,
              }}
              key={index}
            />
          );
        })}
      </Flickity>
    </BannerWrapper>
  );
}

export default Banner;
