import React, { useEffect, useState } from "react";
import { ReviewWrapper, PlaceHolderReview } from "./Styled";
import axios from "axios";
import { SectionTitle, Wrapper } from "../../GlobalStyle";
import Flickity from "react-flickity-component";
import { Star, StarFill } from "./Icons";

const REVIEW_ENDPOINT = "https://node-google-review-crawler.vercel.app/review";

const flickityOptions = {
  initialIndex: 0,
  wrapAround: true,
  autoPlay: true,
  adaptiveHeight: true,
};

const defaultReview = [
  {
    profilePicture:
      "https://lh3.googleusercontent.com/a/AATXAJwh7UE1RSFnM5yPrpYmM1qNuVbhme6OY9RP1gPz=s40-c-c0x00000000-cc-rp-mo-ba4-br100",
    name: "Yihan Lin",
    comment:
      "CAS is a refreshing experience to have for a car workshop. The mechanics are highly trained to service both Asian and Conti car makes. That thing that distincts workshops these days is customer service, and CAS staff are all tuned to move in the same direction. Taking initiative to clean up the engine bay while waiting for the spares to come is defintely worth shouting for. Most workshops would just laze around and wait for the parts to come..but CAS will go above and beyond to ensure your ride is well maintained mechnically and aesthetically. Look for Rodger or Aldrin and they will guide you to make the most practical decision in maintaining your ride. Keep it up CAS!",
    rating: "Rated 5.0 out of 5,",
    time: "3 weeks ago",
  },
  {
    profilePicture:
      "https://lh3.googleusercontent.com/a/AATXAJzFZs00pSJDYoPF6taA8cTjkmZZKVSEMNNKNXdY=s40-c-c0x00000000-cc-rp-mo-br100",
    name: "Koh Jason",
    comment:
      "Overall great experience with Cas Garage, Aldrin is very responsive. Sent him a picture of my car damage and he able to estimate the cost of my repair in a professional way. Every single replacement parts was quoted very clearly and parts use to replace are original from Mitsubishi (for my model). The crew are friendly and workmanship is flawless.. Restore the car back to the original look. Overall very satisfied with their workmanship and service.",
    rating: "Rated 5.0 out of 5,",
    time: "a year ago",
  },
  {
    profilePicture:
      "https://lh3.googleusercontent.com/a/AATXAJz8GnYn3NBQNs5CFE_bfcYA1d56KzEVcH_U1vrg=s40-c-c0x00000000-cc-rp-mo-br100",
    name: "Ismail Fitrey",
    comment:
      "Just had my 90K Servicing and minor repairs. Joey is very friendly and provided great customer service with his product knowledge. Transparent in what they do and fast downtime. Good value for your money. Will be back..",
    rating: "Rated 5.0 out of 5,",
    time: "a year ago",
  },
  {
    profilePicture:
      "https://lh3.googleusercontent.com/a/AATXAJwlSvhnmNNVzavAJqMx5EHCtvY7KrBS4xm-Cjkt=s40-c-c0x00000000-cc-rp-mo-ba2-br100",
    name: "Ricky Thian",
    comment:
      "Staff very friendly and helpful. Keep up the good work. Prices reasonable..👍",
    rating: "Rated 5.0 out of 5,",
    time: "a year ago",
  },
  {
    profilePicture:
      "https://lh3.googleusercontent.com/a-/AOh14Ghwsh5KrFUO_1Nx_3-1saqk73tgO66ptZeckQVE=s40-c-c0x00000000-cc-rp-mo-ba4-br100",
    name: "Roy Chan",
    comment:
      "Awesome service. They never disappoint in their attentiveness and professional service",
    rating: "Rated 5.0 out of 5,",
    time: "11 months ago",
  },
  {
    profilePicture:
      "https://lh3.googleusercontent.com/a-/AOh14GitFRSegSvCnC2kX-1zcp3fWrzbpbqZfuHCrcrRsUo=s40-c-c0x00000000-cc-rp-mo-ba2-br100",
    name: "Denise Wong",
    comment:
      "Experienced and professional accident workshop handled my claim fuss free they settled everything from the time i got into accident till collection of my vehicle and their worksmanship is awesome 😀",
    rating: "Rated 5.0 out of 5,",
    time: "a day ago",
  },
  {
    profilePicture:
      "https://lh3.googleusercontent.com/a-/AOh14GiKOoJ8gCNhzIHGAI7uhIIel_O1MRr2JmIb4TtG_g=s40-c-c0x00000000-cc-rp-mo-br100",
    name: "Kumaran Kumar",
    comment: "",
    rating: "Rated 5.0 out of 5,",
    time: "a year ago",
  },
  {
    profilePicture:
      "https://lh3.googleusercontent.com/a-/AOh14GgP7gUOKmAK-irHzI0Ehlx8Yp2fS7uaULCmBH3f1w=s40-c-c0x00000000-cc-rp-mo-br100",
    name: "Thomas Quek",
    comment: "",
    rating: "Rated 4.0 out of 5,",
    time: "8 months ago",
  },
];

const reviews = require("../../data/reviews.json");

function Review() {
  const [fetching, setFetching] = useState(false);
  // const [reviews, setReviews] = useState([]);
  // useEffect(() => {
  //   axios
  //     .post(REVIEW_ENDPOINT, {
  //       hash: {
  //         iv: "78740ba6c115c481809ff668c2a56051",
  //         content:
  //           "0dfe1489aaafa2f54d778844c17f278bc8e4160e654bd0be6bbe1d4ae3e0125b8d0d929778bdedd077e758fb02bcdd832968c00a8226b16d5fef71c089f983e7a0a4ddb339d5615c72d52daa8a6b6f1e0674526fbd7be7392df01a579f9f78096e0249c214c0fe8ff3e2723c052f0b279fd4807e98729f9d4218b6c2e3b66ac7929a652210362dc42f486e71e2ad55c778cb7ef5d192a445a72b8a0d6f64e9740557be64d36016c144aa66f560c983d25ae17a85dc7d95f9ea0c84fc66776a70cf0c42f22ced7751e2cf80a9074109db15c005c00ddf1bca69316ffb9df029e16bd80eb78e9c31d4af24eb0ec7bada313322be41b672f221c8079f24350e832f5e8f688842c514d3de38fb674d90975f3b643c8e148825176ea4dc9bbb75920cd0bce9ceb52cbd6df81e1a853068e4061afbcd1adde04edb2bc6085577ebae17f5066c24fc711dee02346d9b0927cf02da6ba247e78b452f1ebc65cb4fc8927614cc0420c82a2ac20fda85ae6eac549ab6e8668d07e8d6121f62c7a4335d2d8fcf8351447db61820d72e23d194ab7e068427a73b4ec64b3ed210d4d33f3ab4ec930f1ff580447563947c52fef148faf781a7879548e6357deddfdd2636f5a08507ab0de96ef4817b46496e3906a2a3bfffdad7af0563fee3584240b1b764ce6f668e5fd5e4957a03606806a664c68369d361d387a7ba4d6ba00f5c8aba9906921023b3f2198a63e7554e78143eabb02fbac434d2fc3a81d00cf25318e679e373f6fb94f53543be7dfcd399302348b5445e73b2c7a90ef48b235f9be8369f681768631d3856be77545fcf813ed6045b6bbe547a75476cf655001ce708fbb2dab0b9dcf68c11190ca3cea978aee3b0aa86ca3ea517c6d049e69bd92f21446408fdca86167694c3b52df6009d68a3e7",
  //       },
  //     })
  //     .then((response) => {
  //       const { data } = response;
  //       setReviews(data);
  //       setFetching(false);
  //     });
  // }, []);

  return (
    <ReviewWrapper id="reviews">
      <Wrapper>
        <SectionTitle className="white-font">
          <span>Review</span>
        </SectionTitle>

        {fetching ? (
          <PlaceHolderReview>
            <div className="profile">
              <div className="pic shimmer" />
              <div className="name shimmer" />
            </div>
            <div className="comment shimmer" />
            <div className="comment shimmer" />
            <div className="comment shimmer" />
            <div className="comment shimmer" />
            <div className="comment shimmer" />
          </PlaceHolderReview>
        ) : (
          <Flickity
            className={"carousel"} // default ''
            elementType={"div"} // default 'div'
            options={flickityOptions} // takes flickity options {}
            disableImagesLoaded={false} // default false
            reloadOnUpdate // default false
            static // default false
          >
            {reviews.map((review, index) => {
              const { profilePicture, name, comment, rating, time } = review;
              const stars = Number(
                rating.replace("Rated ", "").replace(".0 out of 5,", "")
              );

              if (!comment || comment === "") return null;

              return (
                <div className="review-item-wrapper" key={profilePicture}>
                  <div className="review-item">
                    <div className="profile">
                      <img src={profilePicture} alt="profile" />
                      <span>{name}</span>
                    </div>
                    <div className="comment">{comment}</div>
                    <div className="rating">
                      <div className="stars-wrapper">
                        {[...new Array(stars).keys()].map((star) => (
                          <StarFill key={`${star}-starfill`} />
                        ))}
                        {[...new Array(5 - stars).keys()].map((star) => (
                          <Star key={`${star}-star`} />
                        ))}
                      </div>
                      {time}
                    </div>
                  </div>
                </div>
              );
            })}
          </Flickity>
        )}
      </Wrapper>
    </ReviewWrapper>
  );
}

export default Review;
