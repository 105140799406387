import styled, { keyframes } from "styled-components";
import {
  MaterialCubicBezier,
  MobileWidth,
  PrimaryColor,
  SectionPadding,
  SectionPaddingMobile,
  SectionTitle,
  Wrapper,
} from "../../GlobalStyle";

import ExclamationMark from "../../images/service-exclamation.png";
import CloseMark from "../../images/service-close.png";
import RoadWinding from "../../images/service-road.svg";
import RoadStraight from "../../images/service-road-straight.svg";

const GlowAnimation = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(2.5);
    opacity: 0;
  }
`;

const roadAnimation = keyframes`
  from {
    background-position-y: 0;
  }
  to {
    background-position-y: -1200px;
  }
`;

const generateRoadAnimation = (wrapperHeight) => {
  return keyframes`
  from {
    background-position-y: 0;
  }
  to {
    background-position-y: -${wrapperHeight}px;
  }
`;
};

const scaleIn = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
`;

export const AccidentWrapper = styled.div`
  color: #fff;
  padding: 40px 0;

  .graphic-wrapper {
    font-size: 14px;
    line-height: 16px;
    height: 815px;
    width: 900px;
    margin: 0 auto;
    position: relative;
    @media screen and (max-width: ${MobileWidth}) {
      font-size: 12px;
      line-height: 14px;
      height: unset;
      width: 100%;
    }

    &:before {
      background: url(${RoadWinding}) top center / contain no-repeat;
      content: "";
      opacity: 0.8;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      transform: rotate(50deg);
      z-index: -1;
      @media screen and (max-width: ${MobileWidth}) {
        background: url(${RoadStraight}) left center / 30px 100% no-repeat;
        transform: rotate(0);
      }
    }

    &.animate {
      .point-box {
        animation: ${scaleIn} 400ms ${MaterialCubicBezier} forwards;
      }
    }

    .point-box {
      position: absolute;
      background-color: rgba(55, 55, 55, 0.2);
      border-radius: 8px;
      width: 350px;
      transform: scale(0);
      backdrop-filter: blur(5px);
      @media screen and (max-width: ${MobileWidth}) {
        margin-bottom: 20px;
        padding-left: 40px;
        position: unset;
        width: 100% !important;
        backdrop-filter: initial;
        background-color: transparent;
      }

      &:before {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        background-color: ${PrimaryColor};
        border-radius: 50%;
        @media screen and (max-width: ${MobileWidth}) {
          display: none;
        }
      }

      &:after {
        content: "";
        position: absolute;
        height: 2px;
        background-color: #fff;
        @media screen and (max-width: ${MobileWidth}) {
          display: none;
        }
      }

      &.point1 {
        left: -50px;
        top: 0;
        transform-origin: right;
        animation-delay: 400ms;
        &:before {
          right: -115px;
        }
        &:after {
          top: 8px;
          width: 95px;
          right: -95px;
        }
      }

      &.point2 {
        left: 560px;
        top: 40px;
        transform-origin: left;
        animation-delay: 600ms;
        &:before {
          left: -223px;
          top: 30px;
        }
        &:after {
          top: 38px;
          width: 205px;
          left: -205px;
        }
      }

      &.point3 {
        left: 560px;
        top: 360px;
        transform-origin: left;
        animation-delay: 800ms;
        &:before {
          left: -140px;
          top: 97px;
        }
        &:after {
          top: 23px;
          width: 145px;
          left: -145px;
          transform-origin: top right;
          transform: rotate(329deg);
        }
      }

      &.point4 {
        left: 560px;
        top: 600px;
        transform-origin: left;
        animation-delay: 1000ms;
        &:before {
          left: -93px;
          top: 15px;
        }
        &:after {
          top: 23px;
          width: 80px;
          left: -80px;
        }
      }

      &.point5 {
        left: 105px;
        top: 369px;
        width: 242px;
      }

      &.point6 {
        left: 534px;
        top: 440px;
      }

      &.point7 {
        bottom: 0;
        left: 430px;
      }

      &.point8 {
        bottom: 30px;
        left: -50px;
        width: 365px;
        animation-delay: 1200ms;
        &:before,
        &:after {
          display: none;
        }
      }
    }

    strong {
      font-weight: 600;
      display: block;
      padding: 8px 10px 8px 30px;
      border-top: 2px solid rgba(55, 55, 55, 0.5);
    }

    ol {
      color: ${PrimaryColor};
      margin: 0 0 2px;
      //padding-left: 15px;
      background-color: rgba(55, 55, 55, 0.5);
      padding: 16px 10px 16px 30px;
      border-radius: 8px 8px 0 0;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 10px 30px;
      li {
        position: relative;
        margin-bottom: 2px;
        &:before {
          border: 2px solid ${PrimaryColor};
          border-radius: 50%;
          content: "";
          height: 4px;
          width: 4px;
          position: absolute;
          left: -15px;
          top: 3px;
        }
      }
    }

    .yellow {
      color: ${PrimaryColor};
      background-color: rgba(55, 55, 55, 0.5);
      padding: 16px 10px 16px 15px;
      border-radius: 8px 8px 0 0;
    }
  }
`;

export const AccidentStraightWrapper = styled.div`
  color: #fff;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 200px;
    background: linear-gradient(
      to bottom,
      #000 0%,
      #000 30%,
      rgba(0, 0, 0, 0.01)
    );
    z-index: 1;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 200px;
    background: linear-gradient(to top, #000 0%, #000 30%, rgba(0, 0, 0, 0.01));
    z-index: 1;
  }

  ${Wrapper} {
    position: relative;
    width: 600px;
    @media screen and (max-width: ${MobileWidth}) {
      width: 300px;
    }

    .road {
      content: "";
      position: absolute;
      width: 60px;
      height: 100%;
      background: #222;
      left: 0;
      top: 0;
      @media screen and (max-width: ${MobileWidth}) {
        width: 30px;
      }

      &:before {
        content: "";
        position: absolute;
        width: 8px;
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0.6) 0%,
          rgba(255, 255, 255, 0.6) 20%,
          #222 20%,
          #222 50%,
          rgba(255, 255, 255, 0.6) 50%,
          rgba(255, 255, 255, 0.6) 70%,
          #222 70%,
          #222 100%
        );
        background-size: ${({ wrapperHeight }) => `${wrapperHeight}px`};
        height: 100%;
        left: calc(30px - 4px);
        box-sizing: border-box;
        top: 0;
        animation: ${({ wrapperHeight }) =>
            generateRoadAnimation(wrapperHeight)}
          3s linear infinite;

        @media screen and (max-width: ${MobileWidth}) {
          left: calc(15px - 2px);
          width: 4px;
        }
      }
    }
  }

  ${SectionTitle} {
    padding-left: 80px;
    padding-top: 40px;
    position: relative;
    z-index: 10;
    @media screen and (max-width: ${MobileWidth}) {
      padding-left: 40px;
    }
  }

  .graphic-wrapper {
    font-size: 14px;
    line-height: 16px;
    width: auto;
    margin: 0 auto;
    padding: 20px 0;
    position: relative;
    z-index: 2;
    @media screen and (max-width: ${MobileWidth}) {
      font-size: 12px;
      line-height: 14px;
    }

    .pin {
      position: relative;
      border-radius: 50%;
      border: 4px solid ${PrimaryColor};
      width: 18px;
      height: 18px;
      left: calc(30px - 2px - 7px);
      top: 12px;
      z-index: 2;
      @media screen and (max-width: ${MobileWidth}) {
        left: -14px;
      }

      &:after {
        border: 7px solid transparent;
        border-top: 14px solid ${PrimaryColor};
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        bottom: -22px;
        left: -2px;
      }
    }

    .point-box {
      position: relative;
      padding-left: 100px;
      margin-bottom: 40px;
      @media screen and (max-width: ${MobileWidth}) {
        padding-left: 30px;
        margin-bottom: 10px;
      }
    }

    strong {
      font-weight: 600;
    }

    ol {
      color: ${PrimaryColor};
      margin: 0 0 2px;
      padding-left: 15px;
    }

    ul {
      list-style: none;
      margin: 0;
      padding-left: 30px;
      li {
        position: relative;
        margin-bottom: 2px;
        &:before {
          border: 2px solid ${PrimaryColor};
          border-radius: 50%;
          content: "";
          height: 4px;
          width: 4px;
          position: absolute;
          left: -15px;
          top: 6px;
        }
      }
    }

    strong {
      padding-left: 16px;
      margin-top: 4px;
      display: block;
    }

    .yellow {
      color: ${PrimaryColor};
    }
  }
`;

export const ExclamationBtn = styled.button`
  cursor: pointer;
  height: 24px;
  width: 24px;
  padding: 0;
  position: relative;
  z-index: 1;
  background: transparent;
  border: 0;
  outline: none;
  @media screen and (max-width: ${MobileWidth}) {
    height: 14px;
    width: 14px;
  }

  &:before {
    content: "";
    background: ${PrimaryColor};
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    z-index: 1;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #999;
    animation: ${GlowAnimation} 2s ${MaterialCubicBezier} infinite;
    z-index: 0;
  }
`;

export const IconImg = styled.img`
  background-color: ${PrimaryColor};
  border-radius: 6px;
  padding: 6px;
  position: relative;
  width: 40px;
  z-index: 1;
`;

export const ServiceMobileModal = styled.div`
  display: none;
  max-width: 300px;
  width: 80%;
  overflow: hidden;
  padding: 8px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) scale(0);
  transition: all 200ms ${MaterialCubicBezier};
  z-index: 20;
  @media screen and (max-width: ${MobileWidth}) {
    display: block;
  }

  &.active {
    transform: translateX(-50%) translateY(-50%) scale(1);
  }

  .btn-close {
    background: url(${CloseMark}) center / 50% no-repeat rgba(0, 0, 0, 0.1);
    border: 2px solid #fff;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .modal-container {
    background-color: rgba(0, 0, 0, 0.8);
    color: #ececec;
    border-radius: 6px;
    font-size: 12px;
    height: auto;
    line-height: 16px;
    overflow-y: auto;
    padding: 15px 10px;

    .modal-name {
      color: #fff;
      margin-bottom: 10px;
    }
  }
`;

export const ServiceSet = styled.div`
  color: #fff;
  position: absolute;

  .item-wrapper {
    position: absolute;

    @media screen and (max-width: ${MobileWidth}) {
      display: none;
    }

    > * {
      transition: all 200ms ${MaterialCubicBezier};
    }

    &.active {
      .line-x {
        transform: scaleX(1);
      }
      .line-y {
        transform: scaleY(1);
      }
      .item-content {
        opacity: 1;
        visibility: visible;
        .desc:before {
          transform: scaleX(1);
        }
      }
    }

    .item-content {
      display: flex;
      align-items: center;
      opacity: 0;
      position: relative;
      visibility: hidden;
      z-index: 2;

      .name {
        font-size: 12px;
        line-height: 12px;
        white-space: nowrap;
      }

      .desc {
        background-color: rgba(0, 0, 0, 0.8);
        border-radius: 6px;
        font-size: 14px;
        line-height: 16px;
        margin-left: 10px;
        padding: 10px;
        position: relative;
        width: 300px;
        white-space: pre-wrap;

        &:before {
          background-color: #fff;
          content: "";
          height: 3px;
          width: 50px;
          position: absolute;
          left: -50px;
          top: 45%;
          transform: scaleX(0);
          transition-duration: 400ms;
          transform-origin: left;
        }
      }
    }

    .line-x,
    .line-y {
      background-color: #fff;
      position: absolute;
      z-index: 0;
    }
    .line-x {
      height: 3px;
      transform: scaleX(0);
    }
    .line-y {
      transform: scaleY(0);
      width: 3px;
    }
  }

  // use id as classname
  /** ******** Servicing ******** */
  &.servicing {
    left: 168px;
    top: 196px;
    @media screen and (max-width: ${MobileWidth}) {
      left: 60px;
      top: 98px;
    }

    .item-wrapper {
      left: 23px;
      top: -135px;

      .line-x {
        left: -11px;
        top: 68px;
        width: 20px;
        transform-origin: left;
        transition-delay: 200ms;
      }

      .line-y {
        height: 77px;
        left: -12px;
        top: 68px;
        transform-origin: bottom;
        transition-delay: 400ms;
      }

      &.active {
        .line-x {
          transition-delay: 200ms;
        }
        .line-y {
          transition-delay: 0ms;
        }
      }
    }
  }

  /** ******** Accident Claims ******** */
  &.claims {
    left: 368px;
    top: 156px;
    @media screen and (max-width: ${MobileWidth}) {
      left: 134px;
      top: 83px;
    }

    .item-wrapper {
      left: 48px;
      top: -145px;

      .line-x {
        left: -37px;
        top: 87px;
        width: 62px;
        transform-origin: left;
        transition-delay: 0ms;
      }

      .line-y {
        height: 60px;
        left: -37px;
        top: 87px;
        transform-origin: bottom;
        transition-delay: 200ms;
      }

      &.active {
        .line-x {
          transition-delay: 200ms;
        }
        .line-y {
          transition-delay: 0ms;
        }
      }
    }
  }

  /** ******** Engine Oil ******** */
  &.oil {
    left: 0;
    top: 215px;
    @media screen and (max-width: ${MobileWidth}) {
      left: 38px;
      top: 130px;
    }

    .item-wrapper {
      bottom: -155px;
      left: -110px;

      .line-x {
        left: 48px;
        top: -103px;
        width: 62px;
        transform-origin: right;
        transition-delay: 200ms;
      }

      .line-y {
        height: 103px;
        left: 48px;
        top: -103px;
        transform-origin: top;
        transition-delay: 0ms;
      }

      &.active {
        .line-x {
          transition-delay: 0ms;
        }
        .line-y {
          transition-delay: 200ms;
        }
      }
    }
  }

  /** ******** Spray Painting ******** */
  &.painting {
    left: 410px;
    top: 215px;
    @media screen and (max-width: ${MobileWidth}) {
      left: 77px;
      top: 133px;
    }

    .item-wrapper {
      bottom: -115px;
      left: 40px;

      .line-x {
        bottom: 52px;
        left: -30px;
        width: 50px;
        transform-origin: left;
        transition-delay: 0ms;
      }

      .line-y {
        bottom: 52px;
        height: 66px;
        left: -30px;
        transform-origin: top;
        transition-delay: 200ms;
      }

      &.active {
        .line-x {
          transition-delay: 200ms;
        }
        .line-y {
          transition-delay: 0ms;
        }
      }
    }
  }

  /** ******** Panel Beating ******** */
  &.panel {
    left: 677px;
    top: 255px;
    @media screen and (max-width: ${MobileWidth}) {
      left: 212px;
      top: 118px;
    }

    .item-wrapper {
      bottom: -149px;
      left: -264px;

      .line-x {
        bottom: 160px;
        left: 40px;
        width: 231px;
        transform-origin: right;
        transition-delay: 200ms;
      }

      .line-y {
        bottom: 96px;
        height: 67px;
        left: 39px;
        transform-origin: top;
        transition-delay: 0ms;
      }

      &.active {
        .line-x {
          transition-delay: 0ms;
        }
        .line-y {
          transition-delay: 200ms;
        }
      }
    }
  }
`;

export const ServiceWrapper = styled.div`
  background-image: url(${require(`../../images/service/service-bg.webp`)
    .default});
  background-position: center;
  background-size: auto 100%;
  background-repeat: no-repeat;
  padding: ${SectionPadding};
  height: 720px;
  text-align: center;
  transition: all 200ms ${MaterialCubicBezier};
  position: relative;
  @media screen and (max-width: ${MobileWidth}) {
    background-image: url(${require(`../../images/service/service-bg-mobile.webp`)
      .default});
    height: 400px;
    padding: ${SectionPaddingMobile};
  }

  .car {
    position: absolute;
    top: 150px;
    left: 50%;
    background: url(${require(`../../images/service/adjustment-layer.webp`)
        .default})
      center / contain no-repeat;
    width: 800px;
    height: 425px;
    transform: translateX(-48.8%);
    filter: hue-rotate(var(--service-bg-hue));
    &.grayscale {
      filter: grayscale(1) brightness(var(--service-bg-brightness));
    }
    @media screen and (max-width: ${MobileWidth}) {
      width: 365px;
      height: 300px;
      top: 79px;
      transform: translateX(-51.8%);
    }
  }

  .car-wrapper {
    display: inline-block;
    position: relative;
    width: 600px;
    @media screen and (max-width: ${MobileWidth}) {
      max-width: 300px;
      width: 100%;
    }

    .car-brakekit {
      background-position: center;
      background-size: contain;
      height: 63px;
      width: 24px;
      position: absolute;
      left: 230px;
      top: 290px;
      transition: all 200ms ${MaterialCubicBezier};

      @media screen and (max-width: ${MobileWidth}) {
        left: 106px;
        top: 150px;
        width: 10px;
        background-repeat: no-repeat;
      }

      &.red {
        background-image: url(${require(`../../images/brakekit/brakekit-small-red.webp`)
          .default});
      }
      &.lime {
        background-image: url(${require(`../../images/brakekit/brakekit-small-lime.webp`)
          .default});
      }
      &.yellow {
        background-image: url(${require(`../../images/brakekit/brakekit-small-yellow.webp`)
          .default});
      }
      &.turquoise {
        background-image: url(${require(`../../images/brakekit/brakekit-small-turquoise.webp`)
          .default});
      }
      &.blue {
        background-image: url(${require(`../../images/brakekit/brakekit-small-blue.webp`)
          .default});
      }
    }
  }

  // .brake-kit-wrapper {
  //   display: flex;
  //
  //   .brake-img {
  //     background: center / 90% no-repeat rgba(0, 0, 0, 0.8);
  //     border-radius: 6px;
  //     height: 160px;
  //     width: 160px;
  //     @media screen and (max-width: ${MobileWidth}) {
  //       height: 80px;
  //       width: 80px;
  //     }
  //
  //     &.blue {
  //       background-image: url(${require(`../../images/brakekit/brakekit-blue.webp`)
    .default});
  //     }
  //     &.lime {
  //       background-image: url(${require(`../../images/brakekit/brakekit-lime.webp`)
    .default});
  //     }
  //     &.yellow {
  //       background-image: url(${require(`../../images/brakekit/brakekit-yellow.webp`)
    .default});
  //     }
  //     &.turquoise {
  //       background-image: url(${require(`../../images/brakekit/brakekit-turquoise.webp`)
    .default});
  //     }
  //     &.red {
  //       background-image: url(${require(`../../images/brakekit/brakekit-red.webp`)
    .default});
  //     }
  //   }
  // }

  .color-row {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 10;
    @media screen and (max-width: ${MobileWidth}) {
      flex-direction: column;
    }

    .color-wrapper {
      @media screen and (max-width: ${MobileWidth}) {
        display: flex;
        align-items: center;
      }

      &.brake-kit {
        margin-left: 50px;
        @media screen and (max-width: ${MobileWidth}) {
          margin-left: 0;
          margin-top: 10px;
        }
      }

      .color-wrapper-title {
        color: #fff;
        margin-bottom: 8px;
        position: relative;
        @media screen and (max-width: ${MobileWidth}) {
          font-size: 12px;
          line-height: 14px;
          margin-bottom: 0;
          width: 82px;
        }
      }
    }

    .color-item-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      .hue-picker {
        @media screen and (max-width: ${MobileWidth}) {
          width: 200px !important;
        }
      }
    }

    .color-item {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #fff;
      margin: 0 10px;
      cursor: pointer;
      border: 2px solid rgba(255, 255, 255, 0.1);
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: calc(100% + 8px);
        height: calc(100% + 8px);
        top: -8px;
        left: -8px;
        border: 4px solid #fff;
        border-radius: 50%;
        transition: all 200ms ${MaterialCubicBezier};
        opacity: 0;
        transform: scale(0);
        @media screen and (max-width: ${MobileWidth}) {
          border-width: 2px;
          height: calc(100% + 4px);
          width: calc(100% + 4px);
          left: -4px;
          top: -4px;
        }
      }

      &.active {
        &:before {
          opacity: 1;
          transform: scale(1);
        }
      }

      &.blue {
        background-color: #03038f;
      }
      &.black {
        background-color: #333;
      }
      &.green {
        background-color: #016001;
      }
      &.purple {
        background-color: #6e036e;
      }
      &.red {
        background-color: #b10000;
      }
      &.lime {
        background-color: #a2f044;
      }
      &.yellow {
        background-color: #f0d344;
      }
      &.turquoise {
        background-color: #44f0d3;
      }
    }
  }
`;

export const GreyscaleToggle = styled.div`
  display: inline-flex;
  position: absolute;
  top: 50%;
  right: 0;
  width: 30px;
  height: 18px;
  background-color: #333;
  border-radius: 20px;
  transform: translateY(-50%);
  cursor: pointer;
  @media screen and (max-width: ${MobileWidth}) {
    top: initial;
    left: 285px;
    right: initial;
    bottom: -2px;
    transform: translateY(0);
  }
  span {
    position: absolute;
    font-size: 10px;
    text-transform: uppercase;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto;
    font-weight: bold;
  }
  input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    background: transparent;
    opacity: 0;
    z-index: 10;
    &:checked + label {
      transform: translateX(12px);
    }
  }
  label {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    left: 1px;
    top: 1px;
    transition: all 200ms ${MaterialCubicBezier};
  }
`;
