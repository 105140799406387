import styled from "styled-components";
import {
  MaterialCubicBezier,
  MobileWidth,
  PrimaryColor,
  SectionPadding,
  SectionPaddingMobile,
} from "../../GlobalStyle";

export const ContactWrapper = styled.div`
  color: #fff;
  padding: ${SectionPadding};
  @media screen and (max-width: ${MobileWidth}) {
    padding: ${SectionPaddingMobile};
  }

  .form-wrapper {
    button {
      border: 4px solid ${PrimaryColor};
      background-color: rgba(0, 0, 0, 0.2);
      color: #fff;
      height: 45px;
      width: 200px;
      position: relative;

      &:before {
        background-color: ${PrimaryColor};
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        transform: scaleX(0);
        transform-origin: left;
        transition: all 400ms ${MaterialCubicBezier};
        z-index: -1;
      }

      &:hover {
        background-color: transparent;
        color: #000;
        font-weight: 600;

        &:before {
          transform: scaleX(1);
        }
      }
    }

    input,
    textarea {
      background-color: rgba(0, 0, 0, 0.2);
      border: 4px solid rgba(255, 255, 255, 0.5);
      color: #fff;
      font-size: 16px;
      font-family: "Poppins", sans-serif;
      width: 100%;
      margin-bottom: 20px;
      outline: 0;
      padding: 12px 18px;
      transition: all 200ms ${MaterialCubicBezier};

      &:active,
      &:focus {
        border-color: ${PrimaryColor};
      }

      &.half {
        width: calc(50% - 10px);
        &:nth-child(even) {
          margin-left: 20px;
        }

        @media screen and (max-width: ${MobileWidth}) {
          margin-left: 0 !important;
          width: 100%;
        }
      }
    }
  }
`;
