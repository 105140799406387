import React from "react";
import Header from "../components/Header";
import Service from "../components/Service";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import ServiceCategory from "../components/ServiceCategory";

function ServicePage() {
  return (
    <>
      <Header />
      <ServiceCategory />
      <Service />
      <Footer />
      <Sidebar />
    </>
  );
}
export default ServicePage;
