import React from "react";
import styled from "styled-components";
import { MobileWidth, PrimaryColor } from "../../GlobalStyle";

function SunocoOil({ setPhotoIndex, setIsOpen }) {
  const openImage = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };
  return (
    <SunocoOilWrapper>
      <div className="sunoco-item" onClick={() => openImage(0)}>
        <div className="sunoco-text">
          <strong>Brill 0W-20</strong>
          <br />
          Produced using “ES-Tech”, our ester technology. The non-polymer
          formulation provides shearing, and in spite of low visicosity, a
          strong oil seal and stable oil flow enables 0W-20 to do the job alone.
        </div>
      </div>

      <div className="sunoco-item" onClick={() => openImage(1)}>
        <div className="sunoco-text">
          <strong>Brill 12.5W-40</strong>
          <br />
          This viscosity has been perfected through our long experience in
          motorsports. The non-polymer formulation for short range driving
          provides a stable oil supply. “ES- Tech” protects turbine bearings,
          and the viscosity has proved perfect in many races including Super GT
          and Super Endurance.
        </div>
        <img
          src={require("../../images/products/Brill_40_Racing_L.png").default}
          alt="Sunoco Oil"
        />
      </div>

      <div className="sunoco-item" onClick={() => openImage(2)}>
        <div className="sunoco-text">
          <strong>Brill 17.5W-50</strong>
          <br />
          The non-polymer “ES-Tech” formulation creates a sufficient oil seal
          for a stable oil supply as well as powerful torque at maximum speed
          driving.
        </div>
        <img
          src={require("../../images/products/Brill_50_Racing_L.png").default}
          alt="Sunoco Oil"
        />
      </div>

      <div className="sunoco-item" onClick={() => openImage(3)}>
        <div className="sunoco-text">
          <strong>Svelt Euro 5W-40</strong>
          <br />
          Conforms to European standard ACEA: A3/B4. Compatible with various
          European car manufacturer certification standards. While conforming to
          API SN Standards, this premium grade covers not only Continental
          vehicles but also Japanese vehicles. By formulating in esters to the
          full synthetic oil, it is an optimal engine oil for sports car.
        </div>
        <img
          src={require("../../images/products/5w-40 svelt L.png").default}
          alt="Sunoco Oil"
        />
      </div>

      <div className="sunoco-item" onClick={() => openImage(4)}>
        <div className="sunoco-text">
          <strong>Svelt Euro 5W-50</strong>
          <br />
          Conforms to European standard ACEA: A3/B4. Compatible with various
          European car manufacturer certification standards. Regardless of the
          season or climate, can be used in a wide range from hot summers to
          cold winters. It is a premium grade that can be used in turbo cars. By
          formulating in esters to the full synthetic oil, it is the optimal
          engine oil for sporty cars (high speed/ winding run) showing excellent
          heat resistance and durability.
        </div>
      </div>

      <div className="sunoco-item" onClick={() => openImage(5)}>
        <div className="sunoco-text">
          <strong>Svelt 5W-30</strong>
          <br />
          Uses “ES- Combination” ester technology. The emphasis is on engine
          protection performance while enhancing the engine’s fuel economy
          performance, and greatly improving detergent dispersant performance
          and anti-wear performance at high temperatures. This highly durable
          motor oil is ideal for road environment which is prone to severe
          conditions.
        </div>
        <img
          src={require("../../images/products/5w-30_Svelt_L.png").default}
          alt="Sunoco Oil"
        />
      </div>

      <div className="sunoco-item" onClick={() => openImage(6)}>
        <div className="sunoco-text">
          <strong>Svelt 0w-20</strong>
          <br />
          Uses “ES-Combination” ester technology. ES- Combination exceeds at a
          high level the requirements for fuel economy, support for exhaust
          after-treatment devices, and longevity demanded by the latest API SP/
          ILSAC GF-6 standards. Focusing on fuel economy in particular, our
          proprietary hybrid additive technology delivers outstanding fuel
          economy performance.
        </div>
      </div>

      <div className="sunoco-item" onClick={() => openImage(7)}>
        <div className="sunoco-text">
          <strong>Qualia 5W-30</strong>
          <br />
          Synthetic oil that uses ES-Combination. Suitable for sports cars,
          turbocharged vehicles, etc., it achieves superior engine protection
          performance together with stable fuel economy performance. The ideal
          engine oil for severe conditions, its reliability prevents engine
          friction and power loss due to excessive heat.
        </div>
        <img
          src={require("../../images/products/5w-30_Qualia_L.png").default}
          alt="Sunoco Oil"
        />
      </div>

      <div className="sunoco-item" onClick={() => openImage(8)}>
        <div className="sunoco-text">
          <strong>Qualia 5W-40</strong>
          <br />
          Synthetic oil that uses ES-Combination. This oil is ideal for high
          mileage vehicles and older vehicles, including those that specify
          5W-40. It exhibits a high sealing effect between the piston rings and
          the cylinders, thereby preventing oil loss through the piston rings
          and valve guides in cars where the clearance has begun to widen.
        </div>
        <img
          src={require("../../images/products/5w-40 Qualia L.png").default}
          alt="Sunoco Oil"
        />
      </div>

      <div className="sunoco-item" onClick={() => openImage(9)}>
        <div className="sunoco-text">
          <strong>Qualia 0W-16</strong>
          <br />
          Synthetic oil that uses ES-Combination. This oil conforms to the
          latest API: SP/ ILSAC: GF-6 B standard and is ideal for hybrid
          vehicles and start/stop system vehicles that specify 0W-16. It
          maximizes fuel economy performance by thoroughly reducing frictional
          resistance in the engine. This engine oil has outstanding evaporation
          resistance despite its low viscosity and is resistant to oil loss even
          after long periods of use.
        </div>
      </div>

      <div className="sunoco-item" onClick={() => openImage(10)}>
        <div className="sunoco-text">
          <strong>Qualia 0W-20</strong>
          <br />
          Synthetic oil that uses ES-Combination. This standard grade oil meets
          the latest API: SP/ ILSAC: GF-6A standards. It is suitable for a wide
          range of vehicle types from hybrid and start/ stop system vehicles
          equipped with direct injection turbo engines. Its anti-wear additives
          have been carefully selected to produce outstanding low-friction
          engine oil.
        </div>
      </div>
    </SunocoOilWrapper>
  );
}

const SunocoOilWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  @media screen and (max-width: ${MobileWidth}) {
    grid-template-columns: 1fr;
  }
  .sunoco-item {
    padding: 30px;
    display: flex;
    align-items: flex-start;
    //border-radius: 10px;
    @media screen and (max-width: ${MobileWidth}) {
      flex-direction: column;
    }
    &:nth-child(even) {
      background-color: #222;
    }
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    img {
      width: 120px;
      border-radius: 10px;
      @media screen and (max-width: ${MobileWidth}) {
        width: 100%;
      }
    }
    .sunoco-text {
      margin-right: 20px;
      font-size: 14px;
      @media screen and (max-width: ${MobileWidth}) {
        margin-left: 0;
        margin-top: 20px;
      }
      strong {
        color: ${PrimaryColor};
      }
    }
  }
`;

export default SunocoOil;
