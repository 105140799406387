import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { MobileWidth } from "../../GlobalStyle";
import { MAX, MIN } from "./index";

function GreyscalePicker({ value, setValue }) {
  useEffect(() => {
    const r = document.querySelector(":root");
    r.style.setProperty("--service-bg-brightness", value);
  }, [value]);

  return (
    <GrayscaleWrapper
      type="range"
      value={value}
      max={MAX}
      min={MIN}
      step={0.01}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}

const GrayscaleWrapper = styled.input`
  width: 316px;
  height: 16px;
  position: relative;
  margin: 0;
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  background: transparent; /* Otherwise white in Chrome */
  @media screen and (max-width: ${MobileWidth}) {
    width: 200px;
  }
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 1px solid #000000;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
    margin-top: -1px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  }

  /* All the same stuff for Firefox */
  &::-moz-range-thumb {
    border: 1px solid #000000;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
  }

  /* All the same stuff for IE */
  &::-ms-thumb {
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
  }

  /* Track */
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 16px;
    cursor: pointer;
    background: linear-gradient(to right, #000, #fff);
    border-radius: 1.3px;
  }

  &:focus::-webkit-slider-runnable-track {
    background: linear-gradient(to right, #000, #fff);
  }

  &::-moz-range-track {
    width: 100%;
    height: 16px;
    cursor: pointer;
    background: linear-gradient(to right, #000, #fff);
    border-radius: 1.3px;
  }

  &::-ms-track {
    width: 100%;
    height: 16px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
  }
  &::-ms-fill-lower {
    background: linear-gradient(to right, #000, #fff);
    border-radius: 2.6px;
  }
  &:focus::-ms-fill-lower {
    background: linear-gradient(to right, #000, #fff);
  }
  &::-ms-fill-upper {
    background: linear-gradient(to right, #000, #fff);
    border-radius: 2.6px;
  }
  &:focus::-ms-fill-upper {
    background: linear-gradient(to right, #000, #fff);
  }
`;

export default GreyscalePicker;
