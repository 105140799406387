import "hamburgers/dist/hamburgers.css";
import React, { useState } from "react";
import { HeaderWrapper } from "./Styled";
import { Wrapper } from "../../GlobalStyle";
import Logo from "../../images/cas-logo.png";
import { NavLink } from "react-router-dom";

const NAV_LIST = [
  {
    title: "HOME",
  },
  {
    title: "ABOUT",
  },
  {
    title: "SERVICES"
  },
  {
    title: "ASSOCIATES",
  },
  {
    title: "PRICING",
  },
  {
    title: "CONTACT",
  },
  {
    title: "REVIEWS",
  }
];

function Anchor(props) {
  return props.to ? <NavLink {...props} /> : <a {...props} />;
}

function Header() {
  const [active, setActive] = useState(NAV_LIST[0].title);
  const [showNav, setShowNav] = useState(false);

  return (
    <HeaderWrapper>
      <Wrapper>
        <img className="logo" src={Logo} alt="Logo" />
        <nav className={`nav ${showNav ? "active" : ""}`}>
          {NAV_LIST.map((nav) => {
            const { title, route } = nav;
            return (
              <Anchor
                key={title}
                to={route}
                href={route ? route : `#${title.toLowerCase()}`}
                className={`nav-item ${active === title ? "active" : ""}`}
                onClick={() => {
                  setActive(title);
                  setShowNav(false);
                }}
              >
                {title}
              </Anchor>
            );
          })}
        </nav>
        <button
          className={`hamburger hamburger--3dx ${showNav ? "is-active" : ""}`}
          type="button"
          onClick={() => setShowNav(!showNav)}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
      </Wrapper>
    </HeaderWrapper>
  );
}

export default Header;
