import Home from "./pages/Home";
import ServicePage from "./pages/Service";
import GlobalStyle from "./GlobalStyle";
import { Switch, Route, Link, Redirect } from "react-router-dom";
import "flickity/css/flickity.css";

export const FB_LINK = "https://www.facebook.com/CASGarageSG/";
export const IG_LINK = "https://instagram.com/cas_garage_sg";
export const ACCIDENT_HOTLINE = "96922220";
export const SERVICE_HOTLINE = "90901222";

function App() {
  return (
    <div className="App">
      <GlobalStyle />
      <Home />
      {/*<Switch>*/}
      {/*  <Route path="/home">*/}
      {/*    <Home />*/}
      {/*  </Route>*/}
      {/*  <Route path="/service">*/}
      {/*    <ServicePage />*/}
      {/*  </Route>*/}
      {/*  <Route path="/">*/}
      {/*    <Redirect to="/home" />*/}
      {/*  </Route>*/}
      {/*</Switch>*/}
    </div>
  );
}

export default App;
