import React, { createRef, useEffect, useState } from "react";
import { MobileWidth, SectionTitle, Wrapper } from "../../GlobalStyle";
import useOnScreen from "../Util/UseOnScreen";
import Accident from "./Accident";
import ServiceItem from "./ServiceItem";
import { ServiceWrapper, GreyscaleToggle } from "./Styled";
import { HuePicker } from "react-color";
import { darken } from "polished";
import GreyscalePicker from "./GreyscalePicker";
import ReactBeforeAfter from "react-before-after";
import BeforeImage from "../../images/serviceCategory/BMW-before.jpg";
import AfterImage from "../../images/serviceCategory/BMW-after.jpg";
import styled from "styled-components";

const Colors = [
  {
    name: "blue",
    hex: "#03038f",
  },
  {
    name: "black",
    hex: "#333",
  },
  {
    name: "green",
    hex: "#016001",
  },
  {
    name: "purple",
    hex: "#6e036e",
  },
  {
    name: "red",
    hex: "#720101",
  },
  {
    name: "white",
    hex: "#ccc",
  },
];
const BrakeColors = ["blue", "lime", "yellow", "turquoise", "red"];

export const MAX = 1.9;
export const MIN = 0.4;

function Service() {
  const carWrapperRef = createRef();
  const isVisible = useOnScreen(carWrapperRef);
  const [isGrey, setIsGrey] = useState(false);
  const [value, setValue] = useState((MAX - MIN) / 2);
  const [hue, setHue] = useState(null);
  const [color, setColor] = useState("#1338A0");
  const [brakeColor, setBrakeColor] = useState(BrakeColors[0]);

  useEffect(() => {
    const r = document.querySelector(":root");
    if (hue) {
      r.style.setProperty("--service-bg-hue", `${hue - 230}deg`);
    }
  }, [color, hue]);

  return (
    <>
      <ServiceWrapper id="services" color={color}>
        <div className={`car ${isGrey ? "grayscale" : ""}`} />
        <Wrapper>
          <SectionTitle className="white-font">
            <span>SERVICES</span>
          </SectionTitle>

          <div className="car-wrapper" ref={carWrapperRef}>
            <div className={`car-brakekit ${brakeColor}`} />
            <ServiceItem isVisible={isVisible} />
          </div>

          {/*<div className="brake-kit-wrapper">*/}
          {/*  <div className={`brake-img ${brakeColor}`} />*/}
          {/*</div>*/}

          <div className="color-row">
            <div className="color-wrapper car-body">
              <div className="color-wrapper-title">
                Car Colours
                <GreyscaleToggle
                  style={isGrey ? { backgroundColor: color } : {}}
                >
                  <span>{isGrey ? "Colour" : "B/W"}</span>
                  <input
                    type="checkbox"
                    value={isGrey}
                    onChange={() => setIsGrey(!isGrey)}
                  />
                  <label />
                </GreyscaleToggle>
              </div>
              <div className="color-item-wrapper">
                {isGrey ? (
                  <GreyscalePicker value={value} setValue={setValue} />
                ) : (
                  <HuePicker
                    color={color}
                    onChange={(e) => {
                      setHue(e.oldHue);
                      setColor(darken(0.2, e.hex));
                      // setSecondaryColor(darken(0.5, e.hex));
                    }}
                  />
                )}
              </div>
            </div>

            <div className="color-wrapper brake-kit">
              <div className="color-wrapper-title">Brake Caliper Colours</div>
              <div className="color-item-wrapper">
                {BrakeColors.map((_brakeColor) => (
                  <div
                    key={_brakeColor}
                    className={`color-item ${_brakeColor} ${
                      _brakeColor === brakeColor ? "active" : ""
                    }`}
                    onClick={() => setBrakeColor(_brakeColor)}
                  />
                ))}
              </div>
            </div>
          </div>
        </Wrapper>
      </ServiceWrapper>

      <PanelBeatingWrapper>
        <Wrapper>
          <p>
            <SectionTitle className="white-font">
              <span>Panel Beating</span>
            </SectionTitle>
            Our panel beating specialists are fully equipped with new tools and
            years of experience to address both minor dents and major incidents.
            We provide the commitment to customers to ensure your car is as good
            as new and on time delivery.
          </p>
          <ReactBeforeAfter
            beforeSrc={BeforeImage}
            afterSrc={AfterImage}
            afterLabel="After"
            height={400}
          />
        </Wrapper>
      </PanelBeatingWrapper>

      <Accident />
    </>
  );
}

const PanelBeatingWrapper = styled.div`
  color: #fff;
  padding: 100px 0;
  background-color: #111;
  @media screen and (max-width: ${MobileWidth}) {
    padding: 40px 0;
  }
  ${SectionTitle} {
    text-align: left;
    @media screen and (max-width: ${MobileWidth}) {
      text-align: center;
    }
  }
  ${Wrapper} {
    display: flex;
    align-items: flex-start;
    @media screen and (max-width: ${MobileWidth}) {
      flex-direction: column;
    }
  }
  p {
    width: 40%;
    margin-right: 20px;
    @media screen and (max-width: ${MobileWidth}) {
      width: 100%;
      margin-right: 0;
      font-size: 12px;
      text-align: center;
    }
  }
`;

export default Service;
