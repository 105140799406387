import React from "react";
import { FB_LINK, IG_LINK, SERVICE_HOTLINE } from "../../App";
import { Wrapper } from "../../GlobalStyle";
import { FooterWrapper } from "./Styled";

import Logo from "../../images/footer-logo.png";

const ICON_SIZE = 18;

function Footer() {
  return (
    <FooterWrapper>
      <Wrapper>
        <div className="footer-flex">
          <div className="col logo">
            <img src={Logo} alt="Cas Garage" />
            <div className="sns-wrapper">
              <a href={FB_LINK} target="_blank">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={ICON_SIZE}
                  height={ICON_SIZE}
                  viewBox="0 0 64 64"
                >
                  <path
                    fill="currentColor"
                    d="M5.1 1.4C.4 4 .1 5.9 0 31.8c0 26.4.4 28.7 5.5 31 4 1.9 49.7 1.7 53.4-.2C63.6 60.1 64 57.8 64 32s-.4-28.1-5.1-30.6c-3.9-2-50.2-2-53.8 0zm44.9 11c.6.8 1 2.5.8 3.8-.3 2-1 2.3-5.1 2.6-4.1.3-4.9.7-5.7 2.8-.6 1.4-1 3.9-1 5.5 0 2.9 0 2.9 6 2.9 6.7 0 8.1 1.3 6.2 5.6-1 2.1-1.8 2.4-6.7 2.4H39v18h-7V38.1l-4.2-.3c-4.2-.3-4.3-.4-4.3-3.8s.1-3.5 4.1-3.8l4.2-.3.4-5.9c.3-4.5 1-6.6 2.8-8.5 3.8-4.1 12.6-5.9 15-3.1z"
                  />
                </svg>
              </a>
              <a href={IG_LINK} target="_blank">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={ICON_SIZE}
                  height={ICON_SIZE}
                  viewBox="0 0 33 33"
                >
                  <path
                    fill="currentColor"
                    d="M5.3 1C1.5 2.6 0 7.1 0 16.7c0 14.8 2.5 16.9 19.4 16.2 7.3-.3 8.9-.7 10.8-2.7 2.1-2.1 2.3-3.1 2.3-13.7s-.2-11.6-2.3-13.7C28.2.7 26.9.5 17.7.3 12.1.1 6.5.5 5.3 1zM28 5c1.7 1.7 2 3.3 2 11.5C30 29.4 29.4 30 16.7 30 3.6 30 3.5 29.9 3.5 16.5S3.6 3 16.7 3c8 0 9.6.3 11.3 2z"
                  />
                  <path
                    fill="currentColor"
                    d="M23.6 6.9c-.7 1.1 1.8 3.3 2.7 2.4.4-.3.7-1.2.7-2 0-1.5-2.6-1.8-3.4-.4zM10.9 10.9c-1.7 1.8-2.9 4-2.9 5.6 0 3.2 4.9 8.5 7.9 8.5 4.8 0 9.1-4 9.1-8.5 0-2.7-2.2-6.3-4.7-7.4-3.9-1.8-6.3-1.3-9.4 1.8zm9.3 1.8c3.4 3.1 1.1 9.3-3.5 9.3-2.7 0-5.7-2.8-5.7-5.3 0-4.8 5.6-7.3 9.2-4z"
                  />
                </svg>
              </a>
            </div>
            <strong>Copyright CAS Garage 2021</strong>
          </div>

          <div className="col address">
            <strong>Address</strong>
            <div>1 Kaki Bukit Avenue 6</div>
            <div>Block D #02-22/21/20</div>
            <div>Autobay</div>
            <div>Singapore 417883</div>
            <strong className="phone-num">{SERVICE_HOTLINE}</strong>
          </div>

          <div className="col map">
            <iframe
              src="https://maps.google.com/maps?q=1%20Kaki%20Bukit%20Avenue%206&t=&z=13&ie=UTF8&iwloc=&output=embed"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
            />
          </div>
        </div>
      </Wrapper>
    </FooterWrapper>
  );
}

export default Footer;
