import styled from "styled-components";
import {MaterialCubicBezier, MobileWidth, PrimaryColor} from "../../GlobalStyle";

export const ServiceCategoryWrapper = styled.div`
  padding: 100px 0;
`;

export const ServiceCategoryTab = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 20px;
`;

export const ServiceCategoryTabItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${PrimaryColor};
  padding: 20px;
  flex-basis: calc(100% / 5);
  cursor: pointer;
  opacity: 0.3;
  transition: all 400ms ${MaterialCubicBezier};
  @media screen and (max-width: ${MobileWidth}) {
    padding: 20px 0;
  }
  &:not(:last-child) {
    margin-right: 20px;
  }
  &.active {
    opacity: 1;
  }
  > img {
    width: 50px;
    margin-bottom: 10px;
    @media screen and (max-width: ${MobileWidth}) {
      width: 30px;
    }
  }
  > span {
    color: #111;
    @media screen and (max-width: ${MobileWidth}) {
      font-size: 12px;
    }
  }
`;

export const ServiceCategoryContent = styled.div`
  color: #fff;
  min-height: 400px;
  display: flex;
  align-items: flex-start;
  .text {
    flex: 1 1 50%;
    text-align: justify;
  }
  .extra {
    flex: 1 0 50%;
    margin-left: 20px;
  }
`;
