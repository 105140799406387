import React, { createRef, useEffect, useState } from "react";
import { TextWrapper } from "./Styled";

const SPECIALISE = [
  "Car Services",
  "Accident Claims",
  "Spray Painting",
  "Panel Beating",
  "Sunoco Engine Oil",
];

function TitleTicker() {
  const titleInterval = createRef();
  const [currentSpecialiseIndex, setCurrentSpecialiseIndex] = useState(0);

  useEffect(() => {
    let specialiseIndex = currentSpecialiseIndex;
    titleInterval.current = setInterval(() => {
      specialiseIndex++;
      if (specialiseIndex === SPECIALISE.length) {
        specialiseIndex = 0;
      }

      setCurrentSpecialiseIndex(specialiseIndex);
    }, 3000);

    return () => clearInterval(titleInterval.current);
  }, []);

  return (
    <TextWrapper>
      <h3 className="sub-title">We Specialise</h3>
      <h1 className="title" key={SPECIALISE[currentSpecialiseIndex]}>
        {SPECIALISE[currentSpecialiseIndex]}
      </h1>
      <a className="cta" href="#services">
        Learn more
      </a>
    </TextWrapper>
  );
}

const MemoizedTitleTicker = React.memo(TitleTicker);

export default MemoizedTitleTicker;
