import React from "react";
import Lightbox from "react-image-lightbox";
import { images } from "./index";

function LightboxComponent({ photoIndex, setPhotoIndex, setIsOpen }) {
  return (
    <Lightbox
      mainSrc={images[photoIndex]}
      nextSrc={images[(photoIndex + 1) % images.length]}
      prevSrc={images[(photoIndex + images.length - 1) % images.length]}
      onCloseRequest={() => setIsOpen(false)}
      onMovePrevRequest={() =>
        setPhotoIndex((photoIndex + images.length - 1) % images.length)
      }
      onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
    />
  );
}

const MemoizedLightboxComponent = React.memo(LightboxComponent);

export default MemoizedLightboxComponent;
