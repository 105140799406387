import React, { createRef, useEffect, useState } from "react";
import { ImageWrapper } from "./Styled";

function ClubItem({ picture, isVisible, delay, size }) {
  const animateRef = createRef();
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    if (isVisible) {
      animateRef.current = setTimeout(() => {
        setIsShow(true);
      }, delay);
    } else {
      setIsShow(false);
    }

    return () => clearTimeout(animateRef.current);
  }, [isVisible]);

  return (
    <ImageWrapper className={isShow ? "active" : ""}>
      <img src={picture} alt="Club" style={size ? { width: size } : {}} />
    </ImageWrapper>
  );
}

export default ClubItem;
