import React, { createRef, useEffect, useState } from "react";
import {
  ExclamationBtn,
  IconImg,
  ServiceMobileModal,
  ServiceSet,
} from "./Styled";

import ClaimsIcon from "../../images/service-claims.png";
import OilIcon from "../../images/service-oil.png";
import PaintingIcon from "../../images/service-spray.png";
import PanelIcon from "../../images/service-panel.png";
import ServicingIcon from "../../images/service-servicing.png";

const ServiceList = [
  {
    id: "servicing",
    name: "Servicing",
    icon: ServicingIcon,
    desc: "We provide inspection, servicing, maintenance and repair services to a wide range of makes and models of passenger cars in Singapore, This includes both continental and JDM cars. Our technicians are well trained to ensure your vehicle runs smoothly after servicing.",
  },
  {
    id: "claims",
    name: "Accident Claims",
    icon: ClaimsIcon,
    desc: "Our reporting specialists are available to assist you in any forms of accidents. They provide professional assessment based on the damage of all vehicles involved in the accident. In addition to managing the claims, they will also facilitate the liaison process with the insurance companies, assist with claims for loss of use and also manage all the paperwork to ensure best value for our customers.",
  },
  {
    id: "oil",
    name: "Engine Oil",
    icon: OilIcon,
    desc: "Engine Oil:\n- LiquiMoly\n- Sunoco Engine Oil",
  },
  {
    id: "painting",
    name: "Spray Painting",
    icon: PaintingIcon,
    desc: "Our Spray Painters ensure that there are minimal to no colour differences after damaged parts are rectified. We also provide full body spray painting.",
  },
  {
    id: "panel",
    name: "Panel Beating",
    icon: PanelIcon,
    desc: "Our panel beating specialists are fully equipped with new tools and years of experience to address both minor dents and major incidents. We provide the commitment to customers to ensure your car is as good as new and on time delivery.",
  },
];

// const INITIAL_SHOWING = ServiceList.find((element) => element.id === "claims");

function ServiceItem({ isVisible }) {
  const animateRef = createRef();
  const [isShow, setIsShow] = useState(false);
  const [currentShowing, setCurrentShowing] = useState({});

  // useEffect(() => {
  //   if (isVisible) {
  //     animateRef.current = setTimeout(() => {
  //       setIsShow(true);
  //       setCurrentShowing(INITIAL_SHOWING);
  //     }, 300);
  //   } else {
  //     setIsShow(false);
  //     setCurrentShowing("");
  //   }
  //
  //   return () => clearTimeout(animateRef.current);
  // }, [isVisible]);

  const checkShow = (item) => {
    if (isShow) {
      if (item.id !== currentShowing.id) {
        setCurrentShowing(item);
      } else {
        setIsShow(false);
        setCurrentShowing({});
      }
    } else {
      setIsShow(true);
      setCurrentShowing(item);
    }
  };

  const closeModal = () => {
    setIsShow(false);
    setCurrentShowing({});
  };

  return (
    <>
      {ServiceList.map((item) => {
        const { id, name, desc, icon } = item;
        return (
          <ServiceSet className={id} key={id}>
            <ExclamationBtn
              onClick={() => {
                checkShow(item);
              }}
            />
            <div
              className={`item-wrapper ${
                currentShowing.id === id && isShow ? "active" : ""
              }`}
            >
              <div className="line-x" />
              <div className="line-y" />
              <div className="item-content">
                <div>
                  <IconImg src={icon} alt={name} />
                  <div className="name">{name}</div>
                </div>
                <div className="desc">{desc}</div>
              </div>
            </div>
          </ServiceSet>
        );
      })}

      <ServiceMobileModal className={isShow ? "active" : ""}>
        <button className="btn-close" onClick={closeModal} />
        <div className="modal-container">
          <IconImg src={currentShowing.icon} alt={currentShowing.name} />
          <div className="modal-name">{currentShowing.name}</div>
          <div className="modal-desc">{currentShowing.desc}</div>
        </div>
      </ServiceMobileModal>
    </>
  );
}

export default ServiceItem;
