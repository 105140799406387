import React, { useState } from "react";
import styled from "styled-components";
import {
  MaterialCubicBezier,
  MobileWidth,
  PrimaryColor,
  SectionPadding,
  SectionPaddingMobile,
} from "../../GlobalStyle";

import PerfectoFlushIcon from "../../images/pump.png";
import EngineOilIcon from "../../images/engine-oil.png";
import SuspensionIcon from "../../images/suspension.png";
import Lightbox from "react-image-lightbox";
import SunocoOil from "./SunocoOil";

const ProductTabs = [
  {
    icon: PerfectoFlushIcon,
    id: "perfecto-flush",
    title: "Perfecto Flush",
    images: [
      require("../../images/products/perfecto_flush_1.jpeg").default,
      require("../../images/products/perfecto_flush_2.jpeg").default,
      require("../../images/products/perfecto_flush_3.jpeg").default,
      require("../../images/products/Machinery Blue.png").default,
      require("../../images/products/Machinery Red.png").default,
      require("../../images/products/Machinery Red v3.png").default,
    ],
    contents: ({ setPhotoIndex, setIsOpen }) => (
      <div>
        <p>
          Suitable for all gasoline and diesel engines with and without a diesel
          particulate filter (DPF). Tested for use with turbochargers and
          catalytic converters.{" "}
        </p>
        <p>The perfect solution for cleaning the engine with running engine.</p>
        <p>Removes black sludge and other acid contaminants in oil line.</p>
        <p>Increases the operational</p>
        <p>reliability of the engine and prevents lubrication deficiency. </p>
        <p>
          Gently cleans oil holes, oil screens, piston ring grooves and ducts
          and prevents engine damage and stop oil burning.{" "}
        </p>
        <p>3-step perfect flush</p>
        <p>
          Active flush work with filter cleans engine oil line sludge and
          contaminants.
        </p>
        <p>
          Intake & exhaust system clean during engine oil line flushing helps
          increasing engine performance.
        </p>
        <p>100% by machine power.</p>
      </div>
    ),
  },
  {
    icon: EngineOilIcon,
    id: "engine-oil",
    title: "Engine Oil",
    images: [
      require("../../images/products/Brill_40_Racing_L.png").default,
      require("../../images/products/Brill_40_Racing_L.png").default,

      require("../../images/products/Brill_50_Racing_L.png").default,

      require("../../images/products/5w-40 svelt L.png").default,
      require("../../images/products/5w-40 svelt L.png").default,

      require("../../images/products/5w-30_Svelt_L.png").default,
      require("../../images/products/5w-30_Svelt_L.png").default,

      require("../../images/products/5w-30_Qualia_L.png").default,

      require("../../images/products/5w-40 Qualia L.png").default,
      require("../../images/products/5w-40 Qualia L.png").default,
      require("../../images/products/5w-40 Qualia L.png").default,
    ],
    contents: ({ setPhotoIndex, setIsOpen }) => (
      <SunocoOil setPhotoIndex={setPhotoIndex} setIsOpen={setIsOpen} />
    ),
  },
  {
    icon: SuspensionIcon,
    id: "suspension",
    title: "Suspension",
    images: [
      require("../../images/products/nitron_1.jpg").default,
      require("../../images/products/nitron_2.jpg").default,
      require("../../images/products/nitron_3.jpg").default,
      require("../../images/products/tractive_1.jpg").default,
      require("../../images/products/tractive_2.jpg").default,
      require("../../images/products/tractive_3.jpg").default,
    ],
    contents: ({ setPhotoIndex, setIsOpen }) => (
      <div>
        <p>Nitron Suspensions</p>
        <p>Tractive Suspensions</p>
      </div>
    ),
  },
];

function Products() {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [currentTab, setCurrentTab] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ProductWrapper>
      <div className="product-tab">
        {ProductTabs.map((product, index) => (
          <div
            className={`product-tab-item ${
              currentTab === index ? "active" : ""
            }`}
            key={product.title}
            onClick={() => setCurrentTab(index)}
          >
            <img src={product.icon} alt={product.title} />
            <span>{product.title}</span>
          </div>
        ))}
      </div>
      <div className="product-content">
        {ProductTabs.map((product, index) => {
          const { images, id, contents } = product;
          return (
            <>
              <div
                className={`product-tab-content ${
                  currentTab === index ? "active" : ""
                }`}
                key={`${product.title}-content`}
              >
                {contents && (
                  <div className={`product-tab-text ${id}`}>
                    {contents({
                      setPhotoIndex,
                      setIsOpen,
                    })}
                  </div>
                )}

                {currentTab !== 1 && (
                  <div className="product-tab-image">
                    {images.map((image, photoIndex) => {
                      const isVideo = image.endsWith(".mp4");
                      return (
                        <div
                          className={`product-image ${isVideo ? "video" : ""}`}
                          key={image}
                          onClick={() => {
                            setPhotoIndex(photoIndex);
                            setIsOpen(true);
                          }}
                        >
                          {isVideo ? (
                            <video controls autoPlay muted>
                              <source src={image} type="video/mp4" />
                            </video>
                          ) : (
                            <img src={image} alt={product.title} />
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </>
          );
        })}
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={ProductTabs[currentTab].images[photoIndex]}
          nextSrc={
            ProductTabs[currentTab].images[
              (photoIndex + 1) % ProductTabs[currentTab].images.length
            ]
          }
          prevSrc={
            ProductTabs[currentTab].images[
              (photoIndex + ProductTabs[currentTab].images.length - 1) %
                ProductTabs[currentTab].images.length
            ]
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + ProductTabs[currentTab].images.length - 1) %
                ProductTabs[currentTab].images.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex(
              (photoIndex + 1) % ProductTabs[currentTab].images.length
            )
          }
        />
      )}
    </ProductWrapper>
  );
}

const ProductWrapper = styled.div`
  p {
    position: relative;
    &:before {
      border: 2px solid ${PrimaryColor};
      border-radius: 50%;
      content: "";
      height: 4px;
      width: 4px;
      position: absolute;
      left: -15px;
      top: 8px;
    }
  }
  .product-tab {
    display: flex;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 20px;
    .product-tab-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #111;
      padding: 20px 40px;
      flex-basis: calc(100% / 3);
      cursor: pointer;
      opacity: 0.5;
      transition: all 400ms ${MaterialCubicBezier};
      @media screen and (max-width: ${MobileWidth}) {
        padding: 20px 0;
      }
      &:not(:last-child) {
        margin-right: 20px;
      }
      &.active {
        opacity: 1;
      }
      > img {
        width: 50px;
        margin-bottom: 10px;
        @media screen and (max-width: ${MobileWidth}) {
          width: 30px;
        }
      }
      > span {
        color: #fff;
        @media screen and (max-width: ${MobileWidth}) {
          font-size: 12px;
        }
      }
    }
  }
  .product-content {
    margin-bottom: 100px;
    .product-tab-content {
      display: none;
      &.active {
        display: block;
      }
      .product-tab-text {
        background-color: #111;
        padding: 20px 40px;
        margin-bottom: 20px;
        &.engine-oil {
          padding: 0;
        }
      }
      .product-tab-image {
        display: flex;
        flex-flow: row wrap;
        .product-image {
          flex-basis: calc((100% / 3) - (40px / 3));
          height: 200px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 20px;
          cursor: pointer;
          @media screen and (max-width: ${MobileWidth}) {
            flex-basis: calc((100% / 2) - (20px / 2));
          }

          &:not(:nth-child(3n)) {
            margin-right: 20px;
            @media screen and (max-width: ${MobileWidth}) {
              margin-right: 0;
            }
          }

          &:not(:nth-child(2n)) {
            @media screen and (max-width: ${MobileWidth}) {
              margin-right: 20px;
            }
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
`;

export default Products;
