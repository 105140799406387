import React, { useState } from "react";
import { SectionTitle, Wrapper } from "../../GlobalStyle";
import { ContactWrapper } from "./Styled";
import axios from "axios";
import cogoToast from "cogo-toast";

const INITIAL_STATE = {
  name: "",
  email: "",
  subject: "",
  message: "",
}

function Contact() {
  const [state, setState] = useState(INITIAL_STATE);

  const handleChange = (e) => {
    setState({
      ...state,

      [e.target.name]: e.target.value,
    });
  };

  const doSubmit = (e) => {
    e.preventDefault();
    const { name, email, subject, message } = state;

    const postBody = {
      name: "CAS Garage",
      subject: `Enquiry from ${name}`,
      text: `Name: ${name}\nEmail: ${email}\nSubject: ${subject}\nMessage: ${message}`,
      hash: {
        iv: "0c1202e22d4c54f43cca7be746af8452",
        content: "11dfa9558181adda7f7019e8ce43787310",
      },
    };

    const postData = JSON.stringify(postBody);
    const options = {
      headers: { "content-type": "application/json" },
    };

    axios
      .post("https://node-mailer-casgarage.vercel.app/api/mail", postData, options)
      .then((res) => {
        console.log(res);
        cogoToast.success("Enquiry Sent!");
        setState(INITIAL_STATE)
      })
      .catch((err) => {
        console.log(err);
        cogoToast.error("Enquiry failed to sent!");
      });
  };

  return (
    <ContactWrapper id="contact">
      <Wrapper>
        <SectionTitle className="white-font">
          <span>Contact</span>
        </SectionTitle>

        <div className="form-wrapper">
          <input
            type="text"
            placeholder="Name"
            className="half"
            name="name"
            value={state.name}
            onChange={handleChange}
          />

          <input
            type="email"
            placeholder="Email"
            className="half"
            name="email"
            value={state.email}
            onChange={handleChange}
          />

          <input
            type="text"
            placeholder="Subject"
            name="subject"
            value={state.subject}
            onChange={handleChange}
          />

          <textarea
            placeholder="Message"
            rows="4"
            name="message"
            value={state.message}
            onChange={handleChange}
          />

          <button onClick={doSubmit}>SUBMIT</button>
        </div>
      </Wrapper>
    </ContactWrapper>
  );
}

export default Contact;
