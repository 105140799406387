import styled from "styled-components";
import {
  MaterialCubicBezier,
  MobileWidth,
  PrimaryColor,
} from "../../GlobalStyle";

const ICON_SIZE = 25;
const MOBILE_ICON_SIZE = 20;

export const SidebarWrapper = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 10;
  @media screen and (max-width: ${MobileWidth}) {
    right: 8px;
  }
`;

export const SidebarLink = styled.a`
  background-color: ${PrimaryColor};
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &:hover {
    svg {
      color: #fff;
    }
    .tooltip {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
    }
  }

  svg {
    color: #000;
    height: ${ICON_SIZE}px;
    width: ${ICON_SIZE}px;
    transition: all 400ms ${MaterialCubicBezier};
    @media screen and (max-width: ${MobileWidth}) {
      height: ${MOBILE_ICON_SIZE}px;
      width: ${MOBILE_ICON_SIZE}px;
    }
  }

  .tooltip {
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 6px;
    color: #fff;
    font-size: 12px;
    opacity: 0;
    padding: 6px 12px;
    position: absolute;
    right: ${ICON_SIZE + 40}px;
    text-align: right;
    transform: translateX(30%);
    transition: all 300ms ${MaterialCubicBezier};
    visibility: hidden;
    white-space: nowrap;
    z-index: -1;
    @media screen and (max-width: ${MobileWidth}) {
      padding: 4px 8px;
      right: ${MOBILE_ICON_SIZE + 30}px;
    }
  }
`;
