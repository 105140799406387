import React from "react";
import Header from "../components/Header";
import Banner from "../components/Banner";
import About from "../components/About";
import Service from "../components/Service";
import Club from "../components/Club";
import Promo from "../components/Promo";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import Reviews from "../components/Reviews";
import Pricing from "../components/Pricing";

function Home() {
  return (
    <>
      <Header />
      <Banner />
      <About />
      <Service />
      <Club />
      <Pricing />
      <Contact />
      <Reviews />
      <Footer />
      <Sidebar />
    </>
  );
}
export default Home;
