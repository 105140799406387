import React from "react";
import { FB_LINK, IG_LINK, ACCIDENT_HOTLINE, SERVICE_HOTLINE } from "../../App";
import { SidebarWrapper, SidebarLink } from "./Styled";

function Sidebar() {
  return (
    <SidebarWrapper>
      <SidebarLink
        target="_blank"
        href={`https://api.whatsapp.com/send?phone=+65${SERVICE_HOTLINE}`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width={33}
          height={33}
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M7.253 18.494l.724.423A7.953 7.953 0 0012 20a8 8 0 10-8-8 7.95 7.95 0 001.084 4.024l.422.724-.653 2.401 2.4-.655zM2.004 22l1.352-4.968A9.954 9.954 0 012 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.954 9.954 0 01-5.03-1.355L2.004 22zM8.391 7.308c.134-.01.269-.01.403-.004.054.004.108.01.162.016.159.018.334.115.393.249.298.676.588 1.357.868 2.04.062.152.025.347-.093.537a4.38 4.38 0 01-.263.372c-.113.145-.356.411-.356.411s-.099.118-.061.265c.014.056.06.137.102.205l.059.095c.256.427.6.86 1.02 1.268.12.116.237.235.363.346.468.413.998.75 1.57 1l.005.002c.085.037.128.057.252.11.062.026.126.049.191.066a.35.35 0 00.367-.13c.724-.877.79-.934.796-.934v.002a.482.482 0 01.378-.127c.06.004.121.015.177.04.531.243 1.4.622 1.4.622l.582.261c.098.047.187.158.19.265.004.067.01.175-.013.373-.032.259-.11.57-.188.733a1.155 1.155 0 01-.21.302 2.378 2.378 0 01-.33.288 3.71 3.71 0 01-.125.09 5.024 5.024 0 01-.383.22 1.99 1.99 0 01-.833.23c-.185.01-.37.024-.556.014-.008 0-.568-.087-.568-.087a9.448 9.448 0 01-3.84-2.046c-.226-.199-.435-.413-.649-.626-.89-.885-1.562-1.84-1.97-2.742A3.47 3.47 0 016.9 9.62a2.729 2.729 0 01.564-1.68c.073-.094.142-.192.261-.305.127-.12.207-.184.294-.228a.961.961 0 01.371-.1z" />
        </svg>
        <span className="tooltip">WhatsApp</span>
      </SidebarLink>

      <SidebarLink href={`tel:+65${ACCIDENT_HOTLINE}`}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <path
            fill="currentColor"
            d="M15 7.6c0 .8.5 1.2 1 .9.6-.3 1-1 1-1.6 0-.5-.4-.9-1-.9-.5 0-1 .7-1 1.6zM18.4 12.1c-2.7 2.8-2.8 3.1-1.1 4.3 1 .8 1.5 2 1.1 2.9-.4 1.2.4 1.8 3.3 2.6 2.1.6 4.2 1.1 4.7 1.1s1.5.8 2.2 1.7c1.1 1.7 1.3 1.6 2.4-1.1 1.6-4.4.3-11.6-2.4-12.5-6-2.2-7.2-2.1-10.2 1zm7.4 0c2.8 1 5.1 4.3 3.9 5.5-.3.3-3-.2-6.2-1.1-5.5-1.6-5.6-1.7-3.9-3.6 2-2.2 2.1-2.2 6.2-.8zM30 21c0 .5-.4 1-1 1-.5 0-1-.5-1-1 0-.6.5-1 1-1 .6 0 1 .4 1 1zM2 15.2C-.4 19.2-.7 26 1.5 26c.8 0 1.5-.5 1.5-1 0-.6 2.5-1 5.5-1s5.5.4 5.5 1c0 .5.7 1 1.5 1 2.1 0 2-5.9-.2-10.5-1.6-3.3-2-3.5-6.5-3.5-4.4 0-5 .3-6.8 3.2zm11-.8c0 .2.3 1.1.6 2 .5 1.3-.3 1.6-5 1.6-3.1 0-5.6-.2-5.6-.4C3 15.1 4.6 14 8.6 14c2.4 0 4.4.2 4.4.4zM4 21c0 .5-.4 1-1 1-.5 0-1-.5-1-1 0-.6.5-1 1-1 .6 0 1 .4 1 1zm11.4 1.1c-.3.5-1 .7-1.5.3-.5-.3-.7-1-.3-1.5.3-.5 1-.7 1.5-.3.5.3.7 1 .3 1.5z"
          />
        </svg>
        <span className="tooltip">Accident Hotline</span>
      </SidebarLink>

      <SidebarLink href={`tel:+65${SERVICE_HOTLINE}`}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 33">
          <path
            fill="currentColor"
            d="M5.9 3.3C.9 6-1.2 9.9 1 12.5c1.9 2.3 7.2 1.6 8.5-1.2 1.8-4.3 12.2-4.3 14 0 1.3 2.8 6.5 3.6 8.5 1.2 2.2-2.6.1-6.5-4.9-9.2C21 .1 12 .1 5.9 3.3z"
          />
          <path
            fill="currentColor"
            d="M8.9 16.8c-3.5 3.5-4.7 5.7-5.3 9-.3 2.4-.4 4.7-.1 5.3.8 1.2 25.2 1.2 26 0 .3-.6.2-2.9-.1-5.3-.6-3.4-1.8-5.4-5.7-9.3-3-3.1-5.1-4.5-5.4-3.8-.6 1.5-3.3 1.7-3.3.3 0-1.9-1.5-1-6.1 3.8zm11.5 3.8c.9.8 1.6 2.2 1.6 3 0 1.9-3.6 5.4-5.5 5.4S11 25.5 11 23.6c0-4 6.4-6 9.4-3z"
          />
          <path
            fill="currentColor"
            d="M13.6 22.6c-.7 1.9 1 4.4 2.9 4.4s3.6-2.5 2.9-4.4c-.8-2.1-5-2.1-5.8 0z"
          />
        </svg>
        <span className="tooltip">Service Hotline</span>
      </SidebarLink>

      <SidebarLink href={FB_LINK} target="_blank">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 33">
          <path
            fill="currentColor"
            d="M15.5 2.5C13.8 4.1 13 6 13 8.5c0 2.8-.4 3.5-2 3.5s-2 .7-2 3 .4 3 2 3c1.8 0 2 .7 2 7.5V33h6v-7.5c0-7.3.1-7.5 2.5-7.5 2.1 0 2.5-.5 2.5-3 0-2.6-.4-3-2.6-3s-2.5-.4-2.2-2.8c.2-2.1.9-2.8 3.1-3 2.3-.3 2.7-.8 2.7-3.3C25 .2 24.7 0 21.5 0c-2.5 0-4.4.8-6 2.5z"
          />
        </svg>
        <span className="tooltip">Facebook</span>
      </SidebarLink>

      <SidebarLink href={IG_LINK} target="_blank">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 33">
          <path
            fill="currentColor"
            d="M5.3 1C1.5 2.6 0 7.1 0 16.7c0 14.8 2.5 16.9 19.4 16.2 7.3-.3 8.9-.7 10.8-2.7 2.1-2.1 2.3-3.1 2.3-13.7s-.2-11.6-2.3-13.7C28.2.7 26.9.5 17.7.3 12.1.1 6.5.5 5.3 1zM28 5c1.7 1.7 2 3.3 2 11.5C30 29.4 29.4 30 16.7 30 3.6 30 3.5 29.9 3.5 16.5S3.6 3 16.7 3c8 0 9.6.3 11.3 2z"
          />
          <path
            fill="currentColor"
            d="M23.6 6.9c-.7 1.1 1.8 3.3 2.7 2.4.4-.3.7-1.2.7-2 0-1.5-2.6-1.8-3.4-.4zM10.9 10.9c-1.7 1.8-2.9 4-2.9 5.6 0 3.2 4.9 8.5 7.9 8.5 4.8 0 9.1-4 9.1-8.5 0-2.7-2.2-6.3-4.7-7.4-3.9-1.8-6.3-1.3-9.4 1.8zm9.3 1.8c3.4 3.1 1.1 9.3-3.5 9.3-2.7 0-5.7-2.8-5.7-5.3 0-4.8 5.6-7.3 9.2-4z"
          />
        </svg>
        <span className="tooltip">Instagram</span>
      </SidebarLink>
    </SidebarWrapper>
  );
}

export default Sidebar;
