import React, { createRef, useEffect, useState } from "react";
import { SectionTitle, Wrapper } from "../../GlobalStyle";
import { AccidentWrapper } from "./Styled";
import useOnScreen from "../Util/UseOnScreen";

function Accident() {
  const animateRef = createRef();
  const isVisible = useOnScreen(animateRef);

  return (
    <AccidentWrapper ref={animateRef}>
      <Wrapper>
        <SectionTitle className="white-font">
          <span>Accident Reporting</span>
        </SectionTitle>

        <div className={`graphic-wrapper ${isVisible ? "animate" : ""}`}>
          <div className="point-box point1">
            <ol>
              <li>Stop the Vehicle. DO NOT Move the vehicle.</li>
            </ol>
            <ul>
              <li>
                Take photos of all vehicles involved in the accident to clearly
                show vehicle positions & damages
              </li>
              <li>
                Take photos of surrounding road location to identify landmarks
              </li>
              <li>DO NOT only focus on vehicle damages.</li>
            </ul>
          </div>

          <div className="point-box point2">
            <ol start={2}>
              <li>
                Check for casualties. Call 999 only IF the accident involves:
              </li>
            </ol>
            <ul>
              <li>Fatalities / Injuries that require medical assistance</li>
              <li>Pedestrians / Cyclists</li>
              <li>Hit & Run</li>
              <li>Damage to government property</li>
              <li>Foreign Vehicles</li>
            </ul>
            <strong>
              * If there are fatalities/injuries, DO NOT attempt to move the
              injured person or vehicle(s).
            </strong>
          </div>

          {/*<div className="point-box point3">*/}
          {/*  <ol start={3}>*/}
          {/*    <li>Gather Evidence of the accident ASAP</li>*/}
          {/*  </ol>*/}
          {/*  <ul>*/}
          {/*    <li>*/}
          {/*      Take photos of surroundings of accident location to identify*/}
          {/*      landmarks*/}
          {/*    </li>*/}
          {/*    <li>*/}
          {/*      All vehicles involved in the accident to show vehicle positions*/}
          {/*    </li>*/}
          {/*    <li>Do not focus purely on damages of the vehicles</li>*/}
          {/*  </ul>*/}
          {/*</div>*/}

          <div className="point-box point3">
            <ol start={3}>
              <li>Exchange information</li>
            </ol>
            <ul>
              <li>NRIC/FIN Number(s)</li>
              <li>Address</li>
              <li>Contact Number</li>
              <li>Vehicle Registration Number & Name of Insurer</li>
              <li>Company Name [If vehicle is company owned]</li>
            </ul>
          </div>

          <div className="point-box point4">
            <ol start={4}>
              <li>Call Our Accident Hotline (+65 9692 2220)</li>
            </ol>
            <ul>
              <li>
                Advise if vehicle is in proper driving condition or requires
                towing service
              </li>
              <li>To seek for immediate advice for any actions to be taken</li>
            </ul>
          </div>

          {/*<div className="point-box point6">*/}
          {/*  <ol start={6}>*/}
          {/*    <li>Lodge a Police Report within 24 hours if:</li>*/}
          {/*  </ol>*/}
          {/*  <ul>*/}
          {/*    <li>Fatalities</li>*/}
          {/*    <li>Pedestrians/Cyclists involved</li>*/}
          {/*    <li>Hit & Run Cases</li>*/}
          {/*    <li>*/}
          {/*      Injuries where the victim had to be conveyed to hospital by*/}
          {/*      ambulance*/}
          {/*    </li>*/}
          {/*    <li>Damages to government property</li>*/}
          {/*    <li>Foreign vehicles</li>*/}
          {/*    <li>*/}
          {/*      Any party involved in the accident was injured and obtained*/}
          {/*      outpatient medical leave for 3 days or more.*/}
          {/*    </li>*/}
          {/*  </ul>*/}
          {/*  <strong>*/}
          {/*    Otherwise, there is no need to lodge the Traffic Police Report*/}
          {/*  </strong>*/}
          {/*</div>*/}

          {/*<div className="point-box point7">*/}
          {/*  <ol start={7}>*/}
          {/*    <li>*/}
          {/*      File a report with your insurer after filing a Traffic Police*/}
          {/*      Report otherwise:*/}
          {/*    </li>*/}
          {/*  </ol>*/}
          {/*  <ul>*/}
          {/*    <li>Insurer may repudiate liability</li>*/}
          {/*    <li>*/}
          {/*      Your No-Claims Discount may be docked upon the renewal of your*/}
          {/*      policy*/}
          {/*    </li>*/}
          {/*    <li>Your insurer may cancel or refuse to renew your policy</li>*/}
          {/*  </ul>*/}
          {/*</div>*/}

          <div className="point-box point8">
            <div className="yellow">
              Documents Required for Accident Reporting:
            </div>
            <ul>
              <li>Driver’s Driving License and NRIC or work permit</li>
              <li>Driver’s Vocational License (For Private Hire Vehicles)</li>
              <li>Owner’s NRIC (should driver is not the vehicle owner)</li>
              <li>Company Stamp (if the vehicle is a company vehicle)</li>
              <li>Certificate of Insurance</li>
              <li>Hire Agreement (For Rental Vehicles)</li>
              <li>Police Report (if any)</li>
              <li>Witness Report (if any)</li>
              <li>
                Evidence (E.g. Accident scene photos and/or video, SD Card,
                Witness Police report)
              </li>
            </ul>
            <strong>
              Beware of touters on the roads. Touting is illegal in Singapore.
            </strong>
          </div>
        </div>
      </Wrapper>
    </AccidentWrapper>
  );
}

export default Accident;
