import styled, {keyframes} from "styled-components";
import {MaterialCubicBezier, MobileWidth, PrimaryColor, Wrapper} from "../../GlobalStyle";

export const ReviewWrapper = styled.div`
  position: relative;
  padding-bottom: 80px;
  ${Wrapper} {
    position: relative;
    height: 100%;
  }
  .carousel {
    background-color: #000;
    .review-item-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .review-item {
        color: #fff;
        max-width: 800px;
        background-color: #111;
        border-radius: 20px;
        padding: 20px;
        text-align: center;
        .profile {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          img {
            border-radius: 50%;
            width: 60px;
          }
          span {
            margin-top: 10px;
            color: ${PrimaryColor};
            font-weight: bold;
          }
        }
        .comment {
          font-size: 14px;
        }
        .rating {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          font-size: 12px;
          color: #666;
          margin-top: 20px;
        }
        .stars-wrapper {
          display: flex;
          align-items: center;
        }
      }
    }
    .flickity-viewport {
      transition: height 200ms ${MaterialCubicBezier};
    }
    .flickity-button {
      display: none;
    }
    .flickity-page-dots {
      //bottom: 50px;
      .dot {
        height: 10px;
        width: 10px;
        border-radius: 10px;
        opacity: 1;
        background-color: rgba(255, 255, 255, 0.5);
        transition: all 200ms ${MaterialCubicBezier};
        &.is-selected {
          background-color: ${PrimaryColor};
        }
      }
    }
  }
`

const shimmer = keyframes`
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
`

export const PlaceHolderReview = styled.div`
  background-color: #111;
  border-radius: 20px;
  padding: 20px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  @media screen and (max-width: ${MobileWidth}) {
    width: 90%;
  }
  .shimmer {
    background: #3a3a3a;
    background-repeat: no-repeat;
    background-size: 800px 200px;
    background-image: linear-gradient(to right, #3a3a3a 0%, #3f3f3f 10%, #4a4a4a 20%, #3f3f3f 30%, #3a3a3a 50%, #3a3a3a 100%);
    animation: ${shimmer} 1s ease-in-out infinite forwards;
  }
  .profile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .pic {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
    .name {
      height: 20px;
      width: 100px;
      margin-top: 12px;
      border-radius: 4px;
    }
  }
  .comment {
    height: 20px;
    width: 300px;
    margin-top: 10px;
    border-radius: 4px;
    @media screen and (max-width: ${MobileWidth}) {
      width: 90%;
    }
  }
`
