import styled from "styled-components";
import {
  MaterialCubicBezier,
  MobileWidth,
  PrimaryColor,
} from "../../GlobalStyle";

export const HeaderWrapper = styled.header`
  position: fixed;
  height: 70px;
  top: 0;
  left: 0;
  right: 0;
  background-color: #000;
  z-index: 21;
  @media screen and (max-width: ${MobileWidth}) {
    height: 50px;
  }
  > div {
    display: flex;
    align-items: center;
    height: 100%;
  }
  .logo {
    height: 100%;
    @media screen and (max-width: ${MobileWidth}) {
      height: 80%;
    }
  }
  .nav {
    margin-left: auto;
    @media screen and (max-width: ${MobileWidth}) {
      z-index: -1;
      position: fixed;
      top: 50px;
      left: 0;
      right: 0;
      height: calc(100vh - 50px);
      background-color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      opacity: 0;
      transform: translateY(-200px);
      visibility: hidden;
      pointer-events: none;
      transition: all 400ms ${MaterialCubicBezier};
      &.active {
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
        pointer-events: auto;
      }
    }
    .nav-item {
      font-family: Poppins, sans-serif;
      font-weight: 700;
      font-size: 14px;
      text-decoration: none;
      color: #fff;
      position: relative;
      @media screen and (max-width: ${MobileWidth}) {
        font-size: 26px;
      }
      &:before {
        content: "";
        position: absolute;
        width: 80%;
        background-color: ${PrimaryColor};
        height: 4px;
        border-radius: 4px;
        bottom: -4px;
        left: 0;
        right: 0;
        margin: 0 auto;
        transform: scaleX(0);
        transition: all 200ms ${MaterialCubicBezier};
      }
      &:not(:last-child) {
        margin-right: 60px;
        @media screen and (max-width: ${MobileWidth}) {
          margin-right: 0;
          margin-bottom: 20px;
        }
      }
      &:hover,
      &.active {
        &:before {
          transform: scaleX(1);
        }
      }
    }
  }
  .hamburger {
    margin-left: auto;
    transform: scale(0.6);
    display: none;
    padding: 0;
    @media screen and (max-width: 400px) {
      display: block;
    }
    .hamburger-inner {
      background-color: #fff;
      transition-duration: 400ms;
      &:before,
      &:after {
        background-color: #fff;
        transition-duration: 400ms;
      }
    }
  }
`;
