import styled from "styled-components";
import {
  MaterialCubicBezier,
  MobileWidth,
  PrimaryColor,
  SectionPadding,
  SectionPaddingMobile,
} from "../../GlobalStyle";

export const ClubWrapper = styled.div`
  background-color: ${PrimaryColor};
  padding: ${SectionPadding};
  @media screen and (max-width: ${MobileWidth}) {
    padding: ${SectionPaddingMobile};
  }

  img {
    margin-bottom: 10px;
    width: 140px;
    @media screen and (max-width: ${MobileWidth}) {
      width: 100px;
    }
  }

  .images-row {
    background-color: #fff;
    padding: 15px 0;

    .row-title {
      color: rgba(0, 0, 0, 0.1);
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
    }

    .logo-wrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-evenly;
    }
  }

  .marquee {
    display: flex;
    overflow: hidden;

    .marquee-content {
      animation: marquee 30s linear infinite;
      display: flex;
      align-items: center;
      will-change: transform;
    }
  }

  @keyframes marquee {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-50%);
    }
  }
`;

export const ImageWrapper = styled.div`
  flex-basis: calc(100% / 5);
  transform: scale(0);
  transition: all 200ms ${MaterialCubicBezier};
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: ${MobileWidth}) {
    flex-basis: calc(100% / 3);
    text-align: center;
  }

  &.active {
    transform: scale(1);
  }
`;
